import React, { Component } from 'react';
import { DataTable, Column } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom'
import { Calendar } from 'primereact/calendar';
import moment from 'moment'
import 'moment/locale/de'
import config from "../config";  // without this line it didn't work

export class BruttoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //year: "2020",
            year: "2020",
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => { console.log("not implemented") }
        };
        //new Date().getFullYear()
    }

    componentDidMount() {
        let year = this.getAllYears()[0]
        if(year != this.state.year)
            this.setState({year})
    }

    onFieldChange(e) {
        let currentFields = this.state.currentFields
        currentFields[e.target.name] = e.target.value
        this.setState({ currentFields })
    }

    getInvoices() {
        return this.props.invoices.filter(invoice => invoice.iss.substr(0,4) == this.state.year).filter(invoice=>invoice.invoice_no!=='-')
    }

    getAllYears() {
        Array.prototype.unique = function() {
          return this.filter(function (value, index, self) { 
            return self.indexOf(value) === index;
          });
        }
        return this.props.invoices.map(invoice => invoice.iss.substr(0,4)).unique()
    }

    yearSelector() {
        return <div >
            Rechnungen für 
                <select onChange={(e)=>{ this.setState({year:e.target.value}) }}>
                    { this.getAllYears().map(year => <option value={year} selected={year == this.state.year}>{year}</option>) }
                </select>
            </div>
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">

                    <Dialog style={{ textAlign: "center" }} header="Fortfahren?" visible={this.state.confirmVisible} modal={true} onHide={() => this.setState({ confirmVisible: false })}
                        footer={<div>
                            <Button onClick={() => { this.setState({ confirmVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                            <Button onClick={() => { this.state.confirmFun(); this.setState({ confirmVisible: false }) }} label="Fortfahren" className="p-button-primary" icon="pi pi-check" />
                        </div>} >{this.state.confirmMessage}</Dialog>


                    {
                        this.getInvoices().length > 0 ?
                            ["awaiting_payment","paid","not_paid"].map((status) => {
                                //let invoicesFilter = this.getInvoices().filter(invoice => invoice.ts_paid.includes(new Date().getFullYear() + "-") || invoice.iss.includes(new Date().getFullYear() + "-")).filter(invoice => invoice.status === status)
                                let invoicesFilter = this.getInvoices().filter(invoice => invoice.status === status)
                                return <div className="card">
                                    <h1>Rechnungen {status === "paid" ? "bezahlt" : status ===  "not_paid" ? "Zahlung ausbleibend":"Zahlung ausstehend"}</h1>
                                    <div className="subtitle">Bitte beachte: Du siehst hier Rechnungen <b>von allen Projekten</b>. Unter <Link to="/invoices">Rechnungen</Link> findest Du Rechnungen, die ausschließlich zum aktuellen Projekt gehören.</div>
                                    { invoicesFilter.length > 0 ? <DataTable value={invoicesFilter}>
                                        <Column field={"invoice_no"} body={o => "R" + o.invoice_no} header={"Rechnung"} style={{ width: "7em", textAlign: "left" }} />
                                        <Column field={"pid"} header={"Projekt"} body={(invoice) => {
                                            return this.props.projects.filter(project => project.id === invoice.pid)[0].slug
                                        }} style={{ width: "8em", textAlign: "left" }}  />
                                        <Column style={{ width: "11em", textAlign: "left" }} field={"status"} body={(rowData) => {
                                            return <span className={"status-" + rowData.status}>{rowData.status === "paid" ? "Bezahlt" : "Zahlungseingang"}</span>
                                        }} header={"Status"} />
                                        <Column field={"iss"} header={"Datum"} />
                                        <Column style={{ textAlign: "right", width: "10em" }} body={(invoice) => {
                                            return invoice.fixeds.reduce((sum, fixed) => {
                                                return sum + parseFloat(fixed.euros)
                                            }, 0).toFixed(2) + "€"
                                        }} header={"Summe"} />
                                        <Column style={{ width: "8em", textAlign: "center" }} body={(rowData, column) => {
                                            return <div>
                                                <Button onClick={() => {
                                                    window.open(config.api_url+"/pdf/?pid=" + rowData.pid + "&id=" + rowData.id + "&type=invoice", "_blank")
                                                }} type="button" style={{ marginRight: 10 }} icon="pi pi-download" className="p-button-secondary"></Button>
                                                {rowData.status === "paid" ||
                                                    <Button disabled={!this.props.api.hasPolicy("invoices_edit")} onClick={() => {
                                                        this.setState({
                                                            confirmVisible: true,
                                                            confirmMessage: "Rechnung R" + rowData.invoice_no + " als bezahlt markieren und SOFORT eine Zahlungsbest&auml;tigung per Email versenden?",
                                                            confirmFun: () => {
                                                                this.props.api.updateInvoice(rowData.id, { status: "paid",
                                                                    sendmail: true
                                                                })
                                                                    .then(d => this.props.refreshProject())
                                                                    .catch(e => alert("Fehler"))
                                                            }
                                                        })
                                                    }} type="button" icon="pi pi-check" className="p-button-success"></Button>
                                                }
                                            </div>
                                        }} />
                                    </DataTable> : <div style={{margin:"10px 15px"}}>Keine Rechnungen für {this.state.year} gefunden.</div>
                                    }
                                    <div style={{ display:"flex", justifyContent: "space-between", marginTop: 10, padding: 10, backgroundColor: status ===  "paid" ? "#A6BF49" : "#375679", color:"#fff", fontSize: "1.2em" }}>

                                    {this.yearSelector()}
                                        
                                        <div>
                                            Summe: <b>{
                                                invoicesFilter.reduce((sum, invoice) => { return sum + invoice.fixeds.reduce((sum, fixed) => sum + parseFloat(fixed.euros), 0) }, 0).toFixed(2)
                                            }€</b>
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="card"> <h1>Rechnungen</h1> <p>Keine Rechnungen gefunden.</p>
                                <div style={{ display:"flex", justifyContent: "space-between", marginTop: 10, padding: 10, backgroundColor:  "#375679", color:"#fff", fontSize: "1.2em" }}>
                                    {this.yearSelector()}
                                </div>
                        </div>
                    }

                    <div className="card">
                        <h1>Einnahmen ausstehend</h1>
                        { this.props.hoursQueue !== undefined &&  this.props.hoursQueue.length > 0 ? 
                            <>
                                <DataTable value={this.props.hoursQueue.filter(hours => hours.euros > 0)}>
                                    <Column field={"pid"} header={"Projekt"} body={(r,c)=>{
                                        return this.props.projects.map((project)=>{
                                            if(r.pid === project.id)
                                                return project.title
                                        })
                                    }} />
                                    <Column field={"max_iss"} header={"Letzte Tätigkeit"} body={(rowData,c) => {

                                        var now = moment(new Date()); //todays date
                                        var end = moment(rowData.iss_max); // another date

                                        var duration = moment.duration(-now.diff(end));

                                        var ms_til_deadline = (duration._milliseconds);

                                        moment.locale('de')

                                        return <span>{duration.humanize(true)}</span>
                                    }} />

                                    <Column field={"mins"} header={"Zeit"} body={(rowData, column) => {
                                            let mins = parseInt(rowData.mins)
                                            return mins === 0 ? <span className="muted">Festpreis</span> :
                                                mins >= 60 ?
                                                    mins % 60 === 0 ? mins / 60 + "h" : Math.floor(mins / 60) + "h " + mins % 60 + "min" :
                                                    mins + "min"
                                        }} />
                                    <Column style={{ textAlign: "right", width: "10em" }} body={(invoice) => {
                                        return parseFloat(invoice.euros).toFixed(2) + "€"
                                        }} header={"Summe"} />
                                    { this.props.api.hasPolicy("invoices_create") && <Column header="Rechnung" style={{ width: "8em", textAlign: "center" }} body={(rowData, column) => {
                                            return <>
                                                <Button onClick={() => {
                                                    this.props.onProjectChoose(rowData.pid) 
                                                    window.location.href='/#/invoices'
                                                }}  icon="pi pi-envelope" /> 
                                            </>
                                        }} />
                                    }
                                </DataTable>
                                <div style={{ textAlign: "right", marginTop: 10, padding: 10, backgroundColor: "#375679", color:"#fff", fontSize: "1.2em" }}>Summe: <b>{
                                    parseFloat(this.props.hoursQueue.reduce((sum, invoice) => { return sum + parseFloat(invoice.euros) }, 0)).toFixed(2)
                                }€</b></div>
                            </>
                            :
                            <p>Keine ausstehenden Einnahmen gefunden.</p>
                        }

                    </div>

                </div>
            </div>
        );
    }
}
