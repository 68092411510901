import React, { Component } from 'react';
import { DataTable, Column } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Growl } from 'primereact/growl';
import { Link } from 'react-router-dom'

import { PickList } from 'primereact/picklist';
import { InputTextarea } from 'primereact/inputtextarea';

export class UsersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false,
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => { console.log("not implemented") },
            currentFields: { fname: "", lname: "", pass: "", email: "" },
            currentAction: "insert"
        };
    }

    onFieldChange(e) {
        let currentFields = this.state.currentFields
        currentFields[e.target.name] = e.target.value
        this.setState({ currentFields })
    }
    showSuccess(detail) {
        this.growl.show({ closable: true, severity: 'success', summary: 'Erfolgreich druchgeführt!', detail });
    }

    showError(detail) {
        this.growl.show({ closable: true, severity: 'error', summary: 'Fehler!', detail });
    }

    prettify(json_string) {
        try {
            json_string = JSON.stringify(JSON.parse(json_string), null, 2)
        } catch (e) {
        }
        return json_string;
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                        <h1>Benutzer</h1>
                        <div className="header-actions">
                            <Button disabled={!this.props.api.hasPolicy("users_create")} icon="pi pi-plus" onClick={() => { this.setState({ currentAction: "insert", currentFields: { fname: "", lname: "", email: "", pass: "" }, dialogVisible: true }) }} label="Hinzufügen" />
                        </div>

                        <div className="subtitle">Benutzer sind die <b>Mitarbeiter von uns</b>, die Kunden findest Du unter <Link to="/customers">Kunden</Link>.</div>

                        <Dialog style={{ textAlign: "center" }} header="Fortfahren?" visible={this.state.confirmVisible} modal={true} onHide={() => this.setState({ confirmVisible: false })}
                            footer={<div>
                                <Button onClick={() => { this.setState({ confirmVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                                <Button onClick={() => { this.state.confirmFun(); this.setState({ confirmVisible: false }) }} label="Fortfahren" className="p-button-primary" icon="pi pi-check" />
                            </div>} >{this.state.confirmMessage}</Dialog>

                        <Dialog header={
                            this.state.currentAction === "insert" ?
                                "Hinzufügen" :
                                this.state.currentAction === "edit" ?
                                    "Bearbeiten" : "Policies für " + this.state.currentFields.fname + " bearbeiten"
                        } visible={this.state.dialogVisible} modal={true} onHide={() => this.setState({ dialogVisible: false })}
                            footer={<div>
                                <Button onClick={() => { this.setState({ dialogVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                                { (this.state.currentAction !== "policies" || this.props.api.hasPolicy("policies_edit")) &&
                                <Button onClick={() => {
                                    if (this.state.currentAction === "insert") {
                                        // add the user
                                        this.props.api.insertUser(this.state.currentFields)
                                            .then((r) => {
                                                this.setState({ dialogVisible: false })
                                                this.showSuccess("Gespeichert.")
                                                this.props.refreshUsers();
                                            })
                                            .catch((e) => {
                                                this.showError("Konnte nicht erstellt werden.")
                                            })
                                    } else {
                                        // update the user
                                        this.props.api.updateUser(this.state.currentFields)
                                            .then((r) => {
                                                this.setState({ dialogVisible: false })
                                                this.showSuccess("Änderungen gespeichert.")
                                                this.props.refreshUsers();
                                            })
                                            .catch((e) => {
                                                this.showError("Speichern der Änderungen fehlgeschlagen.")
                                            })
                                    }
                                }} label={this.state.currentAction === "insert" ? "Hinzufügen" : "Speichern"} icon="pi pi-check" />
                                }
                            </div>}
                            className="form"
                        >
                            {
                                this.state.currentAction === "policies" ?
                                    <div>
                                        <InputTextarea style={{ width: 400, height: "50vh" }} name="policies" value={this.prettify(this.state.currentFields.policies)} onChange={this.onFieldChange.bind(this)} />
                                    </div>
                                    :
                                    <div>
                                        <InputText name="email" value={this.state.currentFields.email} onChange={this.onFieldChange.bind(this)} placeholder="E-mail" />
                                        <InputText name="fname" value={this.state.currentFields.fname} onChange={this.onFieldChange.bind(this)} placeholder="Vorname" />
                                        <InputText name="lname" value={this.state.currentFields.lname} onChange={this.onFieldChange.bind(this)} placeholder="Nachname" />
                                        <Password name="pass" onChange={this.onFieldChange.bind(this)} placeholder="Passwort" />
                                        { this.state.currentAction !== "insert" && <InputTextarea disabled>{"Voreingestellte Zahlungsinformation: "+this.state.currentFields.bankdetails}</InputTextarea> }
                                    </div>
                            }
                        </Dialog>

                        {
                            this.props.users.length > 0 ?
                                <DataTable value={this.props.users}>
                                    <Column field={"email"} header={"E-mail"} body={(row,col)=>{
                                        return <>
                                                <i class="pi pi-lock" style={{cursor:"help",color:(row.secret === "*" ? "green" : "lightgray")}} title={"Für diesen Account ist"+(row.secret  === "" ? " noch keine":"")+" 2-Faktor Authentifizierung aktiviert."}></i> <a href={"mailto:"+row.email}>{row.email}</a> 
                                            </>
                                    }} />
                                    <Column field={"fname"} header={"Vorname"} />
                                    <Column field={"lname"} header={"Nachname"} />
                                    <Column style={{ width: "12em", textAlign: "center" }} body={(rowData, column) => {
                                        return <div>
                                            <Button disabled={!this.props.api.hasPolicy("users_edit")} onClick={() => {
                                                this.setState({ dialogVisible: true, currentAction: "edit", currentFields: rowData }, () => { console.log(this.state) })
                                            }} type="button" icon="pi pi-pencil" className="p-button-secondary"></Button>
                                            <Button disabled={rowData.apikeys && rowData.apikeys.length < 1} onClick={() => {
                                                alert("API Keys:"+rowData.apikeys.map(k=>"\r\n"+"\r\n"+k.apikey+"\r\n// "+k.description))
                                            }} type="button" style={{ marginLeft: 10 }} icon="pi pi-key" className="p-button-secondary"></Button>
                                            <Button disabled={!this.props.api.hasPolicy("policies_list") && !this.props.api.hasPolicy("policies_edit")} onClick={() => {
                                                this.setState({ dialogVisible: true, currentAction: "policies", currentFields: rowData }, () => { console.log(this.state) })
                                            }} type="button" style={{ marginLeft: 10 }} icon="pi pi-tags" className="p-button-secondary"></Button>
                                            <Button disabled={!this.props.api.hasPolicy("users_delete")} onClick={() => {
                                                this.setState({
                                                    confirmVisible: true,
                                                    confirmMessage: "Den User " + rowData.fname + " löschen?",
                                                    confirmFun: () => {
                                                        this.props.api.deleteUser(rowData.id)
                                                            .then(r => {
                                                                if (r.status == 200) {
                                                                    this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                    this.props.refreshUsers();
                                                                } else if (r.status == 420) {
                                                                    this.showError("Du darfst dieses Element nicht löschen.")
                                                                } else {
                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                }
                                                            })
                                                            .catch(r => this.showError("Konnte nicht gelöscht werden."))
                                                    }
                                                })
                                            }} type="button" style={{ marginLeft: 10 }} icon="pi pi-trash" className="p-button-danger"></Button>
                                        </div>
                                    }} />
                                </DataTable>
                                :
                                <p>Keine Elemente gefunden.</p>
                        }

                    </div>
                </div>
            </div>
        );
    }
}
