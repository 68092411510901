import React, {Component} from 'react';
import {DataTable, Column} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Growl} from 'primereact/growl';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import moment from "moment";
import config from "../config";


export class InvoicesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false,
            confirmVisible: false,
            confirmInvoiceNo: 0,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => {
                console.log("not implemented")
            },
            currentFields: {
                pid: 0, id: 0, status: "", iss: "", invoice_no: 0
            },
            sendmail: false,
            currentInsertFields: {
                sendmail: false,
                invoice_no: "",
                start: "", end: "", euros: ""
            },
            currentAction: "insert",
        };
    }

    onFieldChange(e) {
        let currentFields = this.state.currentFields
        currentFields[e.target.name] = e.target.value
        this.setState({currentFields})
    }

    showSuccess(detail) {
        this.growl.show({closable: true, severity: 'success', summary: 'Erfolgreich druchgeführt!', detail});
    }

    showError(detail) {
        this.growl.show({closable: true, severity: 'error', summary: 'Fehler!', detail});
    }

    toggleSendmail(e) {
        this.setState({
            sendmail: !this.state.sendmail,
            confirmMessage: <div>Rechnung R{this.state.confirmInvoiceNo} als bezahlt markieren?<br/><br/><b
                style={{background: "yellow", padding: 5}}>
                <Checkbox checked={!this.state.sendmail} onChange={this.toggleSendmail.bind(this)}/> &nbsp;
                Eine entsprechende Email sofort an den Kunden senden?</b></div>
        });
        console.log("====");
        // this.setState({currentInsertFields: { ...this.state.currentInsertFields, sendmail:!this.state.currentInsertFields.sendmail}})
    }

    render() {

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                        <h1>Rechnungen</h1>
                        <div className="header-actions">
                            <Button
                                disabled={!this.props.api.hasPolicy("invoices_edit") || !this.props.api.hasPolicy("invoices_create")}
                                onClick={() => {
                                    // insert installment
                                    let initial = window.prompt("Initial payment:", "1000");
                                    if (initial === null) return;
                                    let monthly = window.prompt("Monthly payment:", "200");
                                    if (monthly === null) return;
                                    let ag_nr = window.prompt("Angebots NR:", "1234");
                                    if (ag_nr === null) return;
                                    this.props.api.insertInstallment(this.props.pid, {initial, monthly, ag_nr})
                                        .then((r) => {
                                            r.json().then((r) => {
                                                console.log("ratenmodell", r)
                                                if (r.error !== undefined)
                                                    this.showError(r.error)
                                                else {
                                                    this.setState({dialogVisible: false})
                                                    this.props.refreshProject()
                                                    this.showSuccess("Ratenmodell wurde erstellt.")
                                                }
                                            }).catch((e) => {
                                            })
                                        })
                                        .catch((e) => {
                                            this.showError("Ratenmodell konnte nicht erstellt werden.")
                                        })
                                }} label="Ratenmodell"/>
                            <Button
                                disabled={!this.props.api.hasPolicy("invoices_edit") || !this.props.api.hasPolicy("invoices_create")}
                                icon="pi pi-plus" onClick={() => {
                                this.setState({
                                    currentAction: "insert",
                                    currentInsertFields: {invoice_no: "", start: "", end: "", euros: ""},
                                    dialogVisible: true
                                })
                            }} label="Hinzufügen"/>

                        </div>

                        <Dialog style={{textAlign: "center"}} header="Fortfahren?" visible={this.state.confirmVisible}
                                modal={true} onHide={() => this.setState({confirmVisible: false})}
                                footer={<div>
                                    <Button onClick={() => {
                                        this.setState({confirmVisible: false})
                                    }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                    <Button onClick={() => {
                                        this.state.confirmFun();
                                        this.setState({confirmVisible: false})
                                    }} label="Fortfahren" className="p-button-primary" icon="pi pi-check"/>
                                </div>}>{this.state.confirmMessage}</Dialog>

                        <Dialog header={
                            this.state.currentAction === "insert" ?
                                "Hinzufügen" : "Bearbeiten"
                        } visible={this.state.dialogVisible} modal={true}
                                onHide={() => this.setState({dialogVisible: false})}
                                footer={<div>
                                    <Button onClick={() => {
                                        this.setState({dialogVisible: false})
                                    }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                    <Button onClick={() => {
                                        if (this.state.currentAction === "insert") {
                                            // add the invoice
                                            this.props.api.insertInvoice(this.props.pid, this.state.currentInsertFields)
                                                .then((r) => {
                                                    r.json().then((r) => {
                                                        console.log("rr", r)
                                                        if (r.error !== undefined && r.error === "zero_euros")
                                                            this.showError("Die Rechnung hätte eine Zahlungssumme von 0€, daher wurde sie nicht angelegt.")
                                                        else {
                                                            this.setState({dialogVisible: false})
                                                            this.props.refreshProject()
                                                            this.showSuccess("Rechnung wurde erstellt.")
                                                        }
                                                    }).catch((e) => {
                                                    })
                                                })
                                                .catch((e) => {
                                                    this.showError("Rechnung konnte nicht erstellt werden.")
                                                })
                                        } else {
                                            // update the invoice
                                            this.props.api.updateInvoice(this.state.currentFields)
                                                .then((r) => {
                                                    this.setState({dialogVisible: false})
                                                    this.showSuccess("Änderungen gespeichert, lade Projekt neu.")
                                                    this.props.refreshProject()
                                                    this.showSuccess("Projekt neugeladen.")
                                                })
                                                .catch((e) => {
                                                    this.showError("Speichern der Änderungen fehlgeschlagen.")
                                                })
                                        }
                                    }}
                                            label={this.state.currentAction === "insert" ? "Rechnung generieren" : "Speichern"}
                                            icon="pi pi-check"/>
                                </div>}
                                className="form"
                        >
                            <div>
                                {
                                    this.state.currentAction === "insert" ?
                                        <div className="form">
                                            Tätigkeiten die an/nach diesem Datum liegen:
                                            <br/>
                                            <Calendar placeholder="Leer lassen um alle Tätigkeiten auszuwählen"
                                                      value={this.state.currentInsertFields.start}
                                                      onChange={(e) => this.setState({
                                                          currentInsertFields: {
                                                              ...this.state.currentInsertFields,
                                                              start: e.target.value
                                                          }
                                                      })}></Calendar>
                                            Maximal bis zu diesem Datum:
                                            <br/>
                                            <Calendar placeholder="Leer lassen um alle Tätigkeiten auszuwählen"
                                                      value={this.state.currentInsertFields.end}
                                                      onChange={(e) => this.setState({
                                                          currentInsertFields: {
                                                              ...this.state.currentInsertFields,
                                                              end: e.target.value
                                                          }
                                                      })}></Calendar>
                                            Und diese Summe nicht übersteigen:
                                            <br/>
                                            <div className="p-inputgroup">
                                                <InputText placeholder="Leer lassen um keine Grenze festzulegen"
                                                           value={this.state.currentInsertFields.euros}
                                                           onChange={(e) => this.setState({
                                                               currentInsertFields: {
                                                                   ...this.state.currentInsertFields,
                                                                   euros: e.target.value
                                                               }
                                                           }, () => {
                                                               console.log("currentInsert", this.state.currentInsertFields)
                                                           })}/>
                                                <span className="p-inputgroup-addon">€</span>
                                            </div>
                                            {this.props.api.hasPolicy("invoices_create:custom") && <div>
                                                Benutzerdefinierter Rechnungsname:
                                                <br/>
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">R</span>
                                                    <InputText placeholder="Leer lassen um autom. festzulegen"
                                                               value={this.state.currentInsertFields.invoice_no}
                                                               onChange={(e) => this.setState({
                                                                   currentInsertFields: {
                                                                       ...this.state.currentInsertFields,
                                                                       invoice_no: e.target.value
                                                                   }
                                                               }, () => {
                                                                   console.log("invoice_no", this.state.currentInsertFields)
                                                               })}/>
                                                </div>
                                            </div>
                                            }

                                            <b style={{
                                                background: "yellow",
                                                padding: 5,
                                                marginTop: 14,
                                                display: "block"
                                            }}>
                                                <Checkbox checked={this.state.currentInsertFields.sendmail}
                                                          onChange={(e) => this.setState({
                                                              currentInsertFields: {
                                                                  ...this.state.currentInsertFields,
                                                                  sendmail: !this.state.currentInsertFields.sendmail
                                                              }
                                                          })}/> &nbsp;
                                                Eine entsprechende Email sofort an den Kunden senden?</b>
                                        </div>
                                        :
                                        <p>TODO implement</p>
                                }
                                {/* <Dropdown name="status" value={this.state.currentFields.status} options={[{ label: "Zahlung ausstehend", value: "awaiting_payment" }, { label: "Bezahlt", value: "paid" }]} onChange={this.onFieldChange.bind(this)} placeholder="Status der Bezahlung" /> */}
                            </div>
                        </Dialog>

                        {
                            this.props.invoices.length > 0 ?
                                <DataTable value={this.props.invoices.filter(invoice=>invoice.invoice_no!=='-')}>
                                    <Column field={"invoice_no"} body={o => "R" + o.invoice_no} header={"Rechnung"}
                                            style={{width: "8em", textAlign: "left"}}/>
                                    <Column style={{width: "11em", textAlign: "left"}} field={"status"}
                                            body={(rowData) => {
                                                return <span
                                                    className={"status-" + rowData.status}>{rowData.status === "paid" ? "Bezahlt" : "Zahlungseingang"}</span>

                                            }} header={"Status"}/>
                                    <Column field={"iss"} header={"Datum"}/>
                                    <Column style={{textAlign: "right", width: "10em"}} body={(invoice) => {
                                        return invoice.fixeds.reduce((sum, fixed) => {
                                            return sum + parseFloat(fixed.euros)
                                        }, 0).toFixed(2) + "€"
                                    }} header={"Summe"}/>
                                    <Column style={{width: "12em", textAlign: "center"}} body={(rowData, column) => {
                                        return <div>
                                            <Button onClick={() => {
                                                window.open(config.api_url+"/pdf/?pid=" + this.props.pid + "&id=" + rowData.id + "&type=invoice", "_blank")
                                            }} type="button" style={{marginLeft: 10}} icon="pi pi-download"
                                                    className="p-button-secondary"></Button>
                                            <Button
                                                disabled={!this.props.api.hasPolicy("invoices_edit") || rowData.status === "paid"}
                                                onClick={() => {
                                                    this.setState({
                                                        confirmVisible: true,
                                                        confirmInvoiceNo: rowData.invoice_no,
                                                        confirmMessage: <div>Rechnung R{rowData.invoice_no} als bezahlt
                                                            markieren?<br/><br/><b
                                                                style={{background: "yellow", padding: 5}}>
                                                                <Checkbox checked={this.state.sendmail}
                                                                          onChange={this.toggleSendmail.bind(this)}/> &nbsp;
                                                                Eine entsprechende Email sofort an den Kunden
                                                                senden?</b></div>,
                                                        confirmFun: () => {
                                                            this.props.api.updateInvoice(rowData.id, {
                                                                status: "paid",
                                                                sendmail: this.state.sendmail
                                                            })
                                                                .then(d => this.props.refreshProject())
                                                                .catch(e => this.showError("Fehler"))
                                                        }
                                                    })
                                                }} type="button" style={{marginLeft: 10}} icon="pi pi-check"
                                                className="p-button-success"></Button>
                                            <Button
                                                disabled={!this.props.api.hasPolicy("invoices_delete") || rowData.status === "paid2"}
                                                onClick={() => {
                                                    this.setState({
                                                        confirmVisible: true,
                                                        confirmMessage: "Rechnung R" + rowData.invoice_no + " löschen?",
                                                        confirmFun: () => {
                                                            this.props.api.deleteInvoice(rowData.id)
                                                                .then(r => {
                                                                    if (r.status === 200) {
                                                                        this.props.refreshProject()
                                                                        this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                    } else if (r.status == 420) {
                                                                        this.showError("Du darfst dieses Element nicht löschen.")
                                                                    } else {
                                                                        this.showError("Fehlercode: " + r.status)
                                                                    }
                                                                })
                                                                .catch(r => console.log(r))
                                                        }
                                                    })
                                                }} type="button" style={{marginLeft: 10}} icon="pi pi-trash"
                                                className="p-button-danger"></Button>
                                        </div>
                                    }}/>
                                </DataTable>
                                :
                                <p>Keine Elemente gefunden.</p>
                        }

                    </div>
                    <h1>Monatliche Rechnungen</h1>
                    <div className="header-actions">
                        <Button
                            disabled={!this.props.api.hasPolicy("invoices_edit") || !this.props.api.hasPolicy("invoices_create")}
                            icon="pi pi-plus" onClick={() => {

                            // insert invoice cron
                            let max_euros = window.prompt("Welchen Betrag sollen die Rechnungen maximal haben? (0 für keien Grenze)", "500");
                            if (max_euros === null) return;
                            let exec_interval = window.prompt("In welchem Interval (in Sekunden) sollen die Rechnungen gestellt werden? Monatlich(2628000), Jährlich(31536000)", "2628000");
                            if (exec_interval === null) return;
                            let sendmail = window.confirm("Sollen Kund:innenmails versendeet werden?") ? 1 : 0
                            let exec_now = window.confirm("Jetzt sofort ausführen?") ? 1 : 0

                            this.props.api.insertInvoiceCron(this.props.pid, {
                                max_euros,
                                exec_interval,
                                sendmail,
                                exec_now
                            })
                                .then(r => {
                                    console.log("invoice cron", r.status)
                                    if (r.status !== 200)
                                        this.showError(r.error)
                                    else {
                                        this.props.refreshProject()
                                        this.showSuccess("Rechnungs-Cron wurde erstellt.")
                                    }
                                })
                                .catch((e) => {
                                    console.log("Fehler", e)
                                    this.showError("Rechnungs-Cron konnte nicht erstellt werden.")
                                })

                        }} label="Hinzufügen"/>
                    </div>

                    {
                        this.props.invoicecrons &&
                        this.props.invoicecrons.length > 0 ?
                            <DataTable value={this.props.invoicecrons}>
                                <Column field={"exec_interval"}
                                        body={o => {

                                            var duration = moment.duration(o.exec_interval, "seconds")
                                            moment.locale('de')
                                            return duration.humanize()
                                        }
                                            /*  o.exec_interval === "2628000" ?
                                                  "Monatlich" : "Alle " +
                                                  o.exec_interval === "31536000" ?
                                                      "Jährlich" : "Alle " + o.exec_interval + " Sekunden"*/

                                        }
                                        header={"Intervall "}
                                        style={{width: "8em", textAlign: "left"}}/>
                                <Column field={"max_euros"} body={o => o.max_euros + "€"} header={"Max. Euros"}
                                        style={{width: "8em", textAlign: "left"}}/>
                                <Column field={"sendmail"} body={o => o.sendmail === "1" ? "Ja" : "Nein"}
                                        header={"Kund:innen-Mails?"}
                                        style={{width: "8em", textAlign: "left"}}/>
                                <Column field={"last_exec"} body={o => {

                                    if (o.last_execution === "0000-00-00 00:00:00") return "Nie"

                                    var now = moment(new Date()); //todays date
                                    var when = moment(o.last_execution); // another date

                                    var duration = moment.duration(-now.diff(when));

                                    moment.locale('de')

                                    return duration.humanize(true)
                                }
                                } header={"Letzte Ausführung"}
                                        style={{width: "8em", textAlign: "left"}}/>
                                <Column body={o => <Button onClick={() => {
                                    if (!window.confirm("Löschen?")) return
                                    this.props.api.deleteInvoiceCron(this.props.pid, {id: o.id})
                                        .then(d => {
                                            console.log(d)
                                            this.props.refreshProject()
                                        })
                                }} type="button" style={{marginLeft: 10}} icon="pi pi-trash"
                                                           className="p-button-danger"></Button>}
                                        header={"Aktionen"}
                                        style={{width: "8em", textAlign: "left"}}/>
                            </DataTable>
                            :
                            <p>Keine Elemente gefunden.</p>
                    }

                </div>
            </div>
        );
    }
}
