import React, { Component } from 'react';

export class DocsPage extends Component {

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card docs">
            <h1>Datenbank</h1>
            <h2>Clean Projects</h2>
            <p>delete c from Customers c left join Projects p on c.id = p.cid where p.title is null
            </p>
            <h2>About</h2>
            <p>
              uid: User id
              pid: Projekt id
              mins: Minuten, GZ
              euros: Zahlungsbetrag in Euro, FKZ
              iss: Datum der Eintragserstellung, DATETIME
            </p>
            <h2>Besonderheiten</h2>
            <p>
              Tabelle Hours:
              Wenn ein Betrag in euros steht, die Minuten aber leer sind, handelt es sich um einen Festpreis.
            </p>
            <h1>Policies</h1>
            <h2>Mögliche Werte für Polices</h2>
            <textarea style={{ width:"100%",height:700,whiteSpace: "pre-wrap" }}>
              {JSON.stringify({
                "hours": [
                  "list",
                  "create",
                  "edit",
                  "delete"
                ],
                "tasks": [
                  "create",
                  "edit",
                  "delete",
                  "list"
                ],
                "users": [
                  "create",
                  "list",
                  "edit",
                  "delete"
                ],
                "wages": [
                  "list",
                  "delete",
                  "create",
                  "edit"
                ],
                "brutto": [
                  "list",
                  "create",
                  "edit",
                  "delete"
                ],
                "quotes": [
                  "list",
                  "edit",
                  "create",
                  "delete"
                ],
                "invoices": [
                  "list",
                  "edit",
                  "create",
                  "create:custom",
                  "delete"
                ],
                "policies": [
                  "edit",
                  "list"
                ],
                "projects": [
                  "listall",
                  "create",
                  "list",
                  "edit",
                  "delete"
                ],
                "expenses": [
                  "create",
                  "list",
                  "edit",
                  "delete"
                ],
                "customers": [
                  "create",
                  "list",
                  "edit",
                  "delete"
                ],
                "reviews": [
                  "create",
                  "list",
                  "edit",
                  "delete"
                ]
              }, null, 4)}
            </textarea>
          </div>
        </div>
      </div>
    )
  }
}
