import React, {Component} from 'react';
import {DataTable, Column} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Growl} from 'primereact/growl';
import {Dropdown} from 'primereact/dropdown';
import {InputTextarea} from 'primereact/inputtextarea';
import {Link} from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/de'  // without this line it didn't work


import socketIOClient from "socket.io-client";

const ENDPOINT = "https://overtime-socket.onrender.com/";

class DataTableMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {readMoreEnabled: true}

    }

    render() {
        return <div className="data-table-mobile">
            {
                this.props.value.map(row => {
                    return <div className={"data-table-row " + row.type}>
                        {
                            this.props.children.map(o => {
                                return <div style={o.props.style}>
                                    {o.props.header !== undefined && o.props.header + ": "} {o.props.body(row) || row[o.props.field]}
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
    }

}

class ReadMoreDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {readMoreEnabled: this.props.type == "hour"}
    }

    render() {
        let text = this.props.text.split("\n")
        let textnew = ""

        for (let i = 0; i < text.length; i++) {
            if (text[i].substr(0, text[i].length - 1, 1) === ":")
                textnew += "<b>" + text[i] + "</b>"
            else
                textnew += text[i]
            textnew += "\n";
        }

        if (this.props.text.split("\n").length < 2)
            return this.props.text

        textnew = textnew.replace(/LEAD(.?)INFO/gi, '🔥🔥🔥 LEADINFO 🔥🔥🔥')
        return <div className={"textfield firstline-bold " + (textnew.includes("LEADINFO") ? " leadinfo" : "")}>
            <i style={{cursor: "pointer", float: "right"}}
               className={"pi pi-chevron-" + (!this.state.readMoreEnabled ? "down" : "up")}
               onClick={() => this.setState({readMoreEnabled: !this.state.readMoreEnabled})}/>
            {
                this.state.readMoreEnabled ? textnew : <div
                    onClick={() => this.setState({readMoreEnabled: !this.state.readMoreEnabled})}>{textnew.split('\n')[0]}</div>
            }

        </div>
    }
}

export class ConversationsPage extends Component {

    componentDidUpdate() {
        console.log("surveys (for survey debuging)", this.props.surveys)
    }

    componentDidMount() {
        console.log("calling endpoint");
        fetch(ENDPOINT);
    }

    constructor(props) {
        super(props);
        this.textarea = null

        this.state = {
            dialogVisible: false,
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => {
                console.log("not implemented")
            },
            currentFields: {address: "", text: "Kundenkontakt Notizen\n", mins: 0, euros: 0, tid: 0},
            currentPINFields: {pin: window.pin == undefined ? '' : '' + window.pin},
            currentInfomailFields: {subject: "", body: ""},
            currentTaskFields: {title: "", mins: 0, assignee_uid: 0, euros: 0, uid: 0, deadline: "", description: ""},
            currentAction: "insert",

            pinsConnected: [],
            pinEnabled: false,
            pinLog: [],
            pinActions: [
                {
                    "pin": "2974",
                    "type": "hangup",
                    "value": {
                        "title": "Hangup",
                    }
                },
                {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Zur Übersicht",
                        "title": "CarePlus",
                        "href": "https://ziegenhagel.com/services/careplus",
                        "banner_url": "https://www.oecd.org/media/oecdorg/directorates/environmentdirectorate/2020-2/leaves-5308582_1920.jpg",
                        "icon_url": "https://assets.ziegenhagel.com/img/careplus.png"
                    }
                },
                {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Download",
                        "title": "Teamviewer",
                        "href": "https://www.teamviewer.com/de/download/mac-os/?os=osx&language=de&country=DE",
                        "banner_url": "https://cdn.prod.www.spiegel.de/images/3993563a-9d9d-4733-87d9-7e131c1258e2_w1174_r1.33_fpx40.75_fpy49.95.jpg",
                        "icon_url": "https://static.teamviewer.com/resources/2018/07/teamviewer-logo-icon1.png"
                    }
                },
                {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Zur Support Übersicht",
                        "title": "WordPress Guide",
                        "href": "https://ziegenhagel.com/support#wordpress-guide",
                        "banner_url": "https://img.zeit.de/green/2021-09/thema-green-header-bild-neu/wide__1300x731",
                        "icon_url": "https://assets.ziegenhagel.com/img/logo_white.png"
                    }
                }, {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Zur Support Seite",
                        "title": "Sicherung und Update",
                        "href": "https://ziegenhagel.com/support/sicherung-und-update",
                        "banner_url": "https://img.zeit.de/green/2021-09/thema-green-header-bild-neu/wide__1300x731",
                        "icon_url": "https://assets.ziegenhagel.com/img/logo_white.png"
                    }
                }, {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Zur Support Seite",
                        "title": "Menüs bearbeiten",
                        "href": "https://ziegenhagel.com/support/menues-bearbeiten",
                        "banner_url": "https://img.zeit.de/green/2021-09/thema-green-header-bild-neu/wide__1300x731",
                        "icon_url": "https://assets.ziegenhagel.com/img/logo_white.png"
                    }
                },
                {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Link aufrufen",
                        "title": "Weblink",
                        "href": "",
                        "banner_url": "https://static.independent.co.uk/2021/01/30/12/iStock-1131608539or.jpg?width=1200",
                        "icon_url": "https://icon-library.com/images/white-website-icon-png/white-website-icon-png-5.jpg"
                    }
                },

            ]
        };

        const socket = socketIOClient(ENDPOINT);
        socket.on("PIN_CONNECTED", data => {
            this.setState({pinsConnected: [...this.state.pinsConnected, data]})
            if (this.state.currentPINFields.pin == "")
                this.setState({currentPINFields: {pin: data}})
            console.log("PIN received", data)
        });
        socket.on("PIN_DISCONNECTED", data => {
            this.setState({pinsConnected: this.state.pinsConnected.filter(pin => pin != data)})
            console.log("PIN DISCONNECTED", data)
        });
        socket.on("PINS_CONNECTED", data => {
            console.log("PINS CONNECTED", data)
            this.setState({pinsConnected: data})
            if (this.state.currentPINFields.pin == "" && data.length > 0)
                this.setState({currentPINFields: {pin: data[data.length - 1]}})
        });
        socket.emit('OVERTIME', 'Just so you know that I a not a client')

    }

    refreshWages() {
        //TODO remove
        return
        this.props.api.getWages(this.props.pid)
            .then(r => r.json())
            .then(r => this.setState({wages: r.data}))
            .catch(e => console.log(e))
    }

    refreshHours() {
        //TODO remove
        return
        this.setState({pid: this.props.pid}, () => {
            this.props.api.getHours(this.props.pid)
                .then(d => d.json())
                .then(d => {
                    console.log(d.data)
                    this.setState({
                        hours: d.data.filter(hours => (hours.euros === "0" && hours.mins === "0") || hours.text.includes("elefon") || hours.text.includes("undenkontakt")),
                        // hoursAll: d.data,
                        tasks: d.tasks
                    })
                })
                .catch(e => this.showError("Konnte Kundenkontakte nicht aktualisieren."))
            this.refreshWages()
        })
    }

    getWage(mins, uid) {
        console.log(this.props.wages)
        for (let wage of this.props.wages) {
            console.log("checking wage agains uid:" + wage.uid + " vs " + uid)
            if (parseInt(wage.uid) === parseInt(uid) && parseInt(wage.pid) === parseInt(this.props.pid))
                return (mins * parseInt(wage.wage) / 60).toFixed(2)
        }
        return 0
    }

    onTaskFieldChange(e) {
        console.log(this.props.wages)
        let currentTaskFields = this.state.currentTaskFields
        if (e.target.name === "mins" && this.state.currentAction === "task_create") {
            e.target.value = parseInt("0" + e.target.value)
            currentTaskFields.euros = this.getWage(e.target.value, currentTaskFields.assignee_uid)
        }
        currentTaskFields[e.target.name] = e.target.value
        this.setState({currentTaskFields})
    }

    onInfomailFieldChange(e) {

        let currentInfomailFields = this.state.currentInfomailFields
        currentInfomailFields[e.target.name] = e.target.value
        this.setState({currentInfomailFields})

    }

    onEventFieldChange(e) {

        let currentPINFields = this.state.currentPINFields
        currentPINFields[e.target.name] = e.target.value
        this.setState({currentPINFields})

    }

    onFieldChange(e) {

        let currentFields = this.state.currentFields

        if (e.target.name === "mins" && this.state.currentAction === "insert") {
            e.target.value = parseInt("0" + e.target.value)
            currentFields.euros = this.getWage(e.target.value, this.props.api.getUserfield("id")) // TODO replace own
        }
        currentFields[e.target.name] = e.target.value
        this.setState({currentFields})
    }

    showSuccess(detail) {
        if (this.growl !== undefined)
            this.growl.show({closable: true, severity: 'success', summary: 'Erfolgreich druchgeführt!', detail});
    }

    showError(detail) {
        if (this.growl !== undefined)
            this.growl.show({closable: true, severity: 'error', summary: 'Fehler!', detail});
        else
            console.error("couldnt show error: " + detail)
    }

    getSurveyProjectRatings_toArray() {
        try {

            let data_as_object = JSON.parse(this.props.surveys?.project?.data)
            let data_as_array = []
            let keys = Object.keys(data_as_object)

            // convert data_as_object to array

            // foreach key
            for (let i = 0; i < keys.length; i++) {
                let question = keys[i]
                data_as_array.push({...data_as_object[question], question})
            }

            console.log("suces parse of survey project ratings", data_as_array)
            return data_as_array


        } catch (e) {
            console.log("Catchinf parse of survey project ratings", e)
            return []
        }
    }

    getSurveyProjectStructure() {
        try {
            return JSON.parse(this.props.surveys?.project?.data_structure)
        } catch (e) {
            return []
        }
    }

    render() {
        console.log("cproj", this.props)

        return (
            <div className="p-grid">

                <div className="p-col-6">
                    <div className="card">
                        <h1>Telefon PIN</h1>
                        <div
                            style={{
                                padding: 10,
                                paddingBottom: 0,
                                color: this.state.pinsConnected.includes(this.state.currentPINFields.pin) ? 'green' : 'red'
                            }}>
                            NowPIN: <a onClick={() => {
                            console.log('reseeting pin')
                            this.setState({
                                pinEnabled: false,
                                currentPINFields: {pin: this.state.pinsConnected == [] ? "" : this.state.pinsConnected[this.state.pinsConnected.length - 1]}
                            })
                        }}>{this.state.currentPINFields.pin}</a>
                            , Pins Connected: {this.state.pinsConnected.join(', ')}
                        </div>

                        {
                            this.state.pinEnabled ?
                                <div style={{padding: 10}}>
                                    Links senden:<br/>
                                    {this.state.pinActions.map(payload => {
                                        return <Button style={{marginRight: 4, marginBottom: 4}}
                                                       label={payload.value.title} onClick={() => {
                                            fetch(ENDPOINT + 'payload', {
                                                headers: {"content-type": "application/json"},
                                                method: "post", body: JSON.stringify({
                                                    ...payload,
                                                    value: {
                                                        ...payload.value,
                                                        title: payload.value.title === 'Weblink' ? this.state.currentInfomailFields.subject : payload.value.title,
                                                        href: payload.value.title === 'Weblink' ? this.state.currentInfomailFields.body : payload.value.href,
                                                    },
                                                    pin: window.pin
                                                })
                                            }).then(d => d.json()).then(d => {
                                                if (!d.succes) window.alert(' Fehler')
                                                else this.setState({pinLog: [...this.state.pinLog, payload]})
                                            })
                                        }}/>
                                    })}
                                    <hr/>
                                    Verlauf:
                                    {this.state.pinLog.map((payload, index) => {
                                        return <li>{index + 1}. {payload.value.title}</li>
                                    })}
                                </div>
                                :
                                <div style={{display: "flex", "height": "100%", padding: "10px"}}>
                                    <InputText name="pin" style={{width: "100%", marginRight: 4}}
                                               value={this.state.currentPINFields.pin}
                                               onChange={this.onEventFieldChange.bind(this)} placeholder="PIN"/>
                                    <br/>
                                    <Button onClick={() => {
                                        let pin = this.state.currentPINFields.pin
                                        window.pin = pin
                                        fetch(ENDPOINT + 'user', {
                                            headers: {"content-type": "application/json"},
                                            method: "post", body: JSON.stringify({
                                                pin,
                                                "user": {
                                                    "name": this.props.customer.fname + " " + this.props.customer.lname,
                                                    "employee": this.props.api.getUserfield("fname")
                                                }
                                            })
                                        }).then(d => d.json()).then(d => {
                                            if (!d.succes) window.alert(' Fehler')
                                            else this.setState({pinEnabled: true})
                                        })
                                        console.log('PIN', pin)
                                    }} style={{width: "100%"}} label="PIN bestätigen" className="p-button-primary"
                                            icon="pi pi-check"/>
                                </div>
                        }
                    </div>
                </div>


                <div className="p-col-6">
                    <div className="card">
                        <h1>Info-Mail versenden</h1>
                        <div style={{display: "block", "height": "100%", padding: "20px"}}>
                            <InputText name="subject" style={{width: "100%"}}
                                       value={this.state.currentInfomailFields.subject}
                                       onChange={this.onInfomailFieldChange.bind(this)} placeholder="Betreff"/>
                            <InputTextarea name="body" style={{marginTop: 6, width: "100%"}}
                                           value={this.state.currentInfomailFields.body}
                                           onChange={this.onInfomailFieldChange.bind(this)} placeholder="Inhalt"/>
                            <br/>
                            <Button onClick={() => {
                                if (window.confirm("Info - Mail wird SOFORT AN KUND*IN gesendet, fortfahren?")) {
                                    this.props.api.sendInfomail(this.props.pid, this.state.currentInfomailFields)
                                        .then((r) => {
                                            this.showSuccess("Info-Mail gesendet.")
                                        })
                                        .catch((e) => {
                                            this.showError("Info-Mail konnte nicht erstellt werden.")
                                        })
                                } else {
                                    this.showError("Info-Mail-Bestätigung abgebrochen.")
                                }

                            }} style={{width: "100%"}} label="Info-Mail senden" className="p-button-primary"
                                    icon="pi pi-check"/>
                        </div>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="card">

                        <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                        <h1>Kundenkontakt</h1>
                        <div className="header-actions">

                            {this.state.pinEnabled &&
                                <Button icon="pi pi-wifi" onClick={() => {

                                    /* PIN SEND SOCKET */
                                    let data = {
                                        pin: window.pin, type: 'hours',
                                        value: this.props.hours.filter(hour => hour.status != 'paid' && (hour.euros != "0" || hour.mins != "0")).map(({
                                                                                                                                                          status,
                                                                                                                                                          iss,
                                                                                                                                                          mins,
                                                                                                                                                          euros,
                                                                                                                                                          text
                                                                                                                                                      }) => {
                                            return {status, iss, mins, euros, text}
                                        })
                                    }

                                    console.log("Socket emit", data)

                                    fetch('https://overtime-socket.onrender.com/payload', {
                                        headers: {"content-type": "application/json"},
                                        method: "post", body: JSON.stringify(data)
                                    }).then(d => d.json()).then(d => {
                                        if (!d.succes) window.alert(' Fehler')
                                    })


                                }}/>
                            }

                            {
                                this.props.customer != null && this.props.customer.phone.length > 0 &&
                                <Button icon="pi pi-mobile" onClick={() => {
                                    window.location.href = 'tel:' + this.props.customer.phone
                                }} label="Anruf"/>
                            }
                            <Button disabled={!this.props.api.hasPolicy("hours_create")} icon="pi pi-plus"
                                    onClick={() => {
                                        this.setState({
                                            currentAction: "insert",
                                            currentFields: {pid: this.props.pid, mins: 0, euros: 0, tid: 0},
                                            dialogVisible: true
                                        })
                                    }} label="Hinzufügen"/>
                        </div>

                        <div className="subtitle">Dies ist eine reduzierte Darstellung <Link to="/hours">aller
                            Tätigkeiten</Link>.<br/>
                            Als Kundenkontakt zählen alle Tätigkeiten, die die Stichworte "Telefonieren" oder
                            "Kundenkontakt" enthalten. Tätigkeiten ohne entstandene Kosten bzw. Zeit zählen ebenfalls
                            als Kundenkontakt.
                            <br/>
                            Benutze das Stichwort "Leadinfo", wenn der Kontakt hervorgehoben werden soll.
                        </div>

                        <Dialog style={{textAlign: "center"}} header="Fortfahren?" visible={this.state.confirmVisible}
                                modal={true} onHide={() => this.setState({confirmVisible: false})}
                                footer={<div>
                                    <Button onClick={() => {
                                        this.setState({confirmVisible: false})
                                    }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                    <Button onClick={() => {
                                        this.state.confirmFun();
                                        this.setState({confirmVisible: false})
                                    }} label="Fortfahren" className="p-button-primary" icon="pi pi-check"/>
                                </div>}>{this.state.confirmMessage}</Dialog>

                        <Dialog header={
                            this.state.currentAction === "insert" ? "Tätigkeit registrieren" :
                                this.state.currentAction === "task_create" ?
                                    "Aufgabe anlegen" : "Bearbeiten"
                        } visible={this.state.dialogVisible} modal={true}
                                onHide={() => this.setState({dialogVisible: false})}
                                footer={<div>
                                    <Button onClick={() => {
                                        this.setState({dialogVisible: false})
                                    }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                    <Button onClick={() => {
                                        if (this.state.currentAction === "insert") {
                                            // add the hour
                                            this.props.api.insertHour(this.props.pid, this.state.currentFields)
                                                .then((r) => {
                                                    this.setState({
                                                        dialogVisible: false,
                                                        currentFields: {
                                                            ...this.state.currentFields,
                                                            text: "Kundenkontakt weitere Notizen\n"
                                                        }
                                                    })
                                                    this.props.refreshHours()
                                                    this.showSuccess("Gespeichert.")
                                                })
                                                .catch((e) => {
                                                    console.log("e", e)
                                                    this.showError("Tätigkeit konnte nicht erstellt werden.")
                                                })
                                        } else if (this.state.currentAction === "task_create") {
                                            // add the hour
                                            this.props.api.insertTask(this.props.pid, this.state.currentTaskFields)
                                                .then((r) => {
                                                    this.setState({dialogVisible: false})
                                                    this.props.refreshHours()
                                                    this.showSuccess("Gespeichert.")
                                                })
                                                .catch((e) => {
                                                    console.log("e", e)
                                                    this.showError("Aufgabe konnte nicht erstellt werden.")
                                                })
                                        } else {
                                            // update the hour
                                            this.props.api.updateHour(this.state.currentFields)
                                                .then((r) => {
                                                    this.setState({dialogVisible: false})
                                                    this.showSuccess("Änderungen gespeichert.")
                                                    this.props.refreshHours()
                                                })
                                                .catch((e) => {
                                                    this.showError("Speichern der Änderungen fehlgeschlagen.")
                                                })
                                        }
                                    }} label={this.state.currentAction !== "edit" ? "Hinzufügen" : "Speichern"}
                                            icon="pi pi-check"/>
                                </div>}
                                className="form"
                        >
                            <div>
                                <div>
                                    <InputTextarea autoFocus={true} style={{width: "80vw", height: "50vh"}}
                                                   autoFocus={true} name="text" value={this.state.currentFields.text}
                                                   onChange={this.onFieldChange.bind(this)}
                                                   placeholder={`Tätigkeit beschreiben`}/>
                                    <InputText name="address" value={this.state.currentFields.address}
                                               onChange={this.onFieldChange.bind(this)}
                                               placeholder={`Addresse (falls eine Fahrt damit verbunden war, z.B. Berlin, Freiburg)`}/>
                                    <div className="p-inputgroup">
                                        <InputText name="mins" value={this.state.currentFields.mins}
                                                   onChange={this.onFieldChange.bind(this)} placeholder="Minuten"/>
                                        <span className="p-inputgroup-addon">min</span>
                                    </div>
                                    <div className="p-inputgroup">
                                        <InputText name="euros" value={this.state.currentFields.euros}
                                                   onChange={this.onFieldChange.bind(this)}
                                                   placeholder="Betrag in Euro"/>
                                        <span className="p-inputgroup-addon">€</span>
                                    </div>
                                    <Dropdown name="tid" style={{display: "none"}}
                                              options={this.props.tasks.map((task) => {
                                                  return {label: task.title, value: task.id}
                                              })} value={this.state.currentFields.tid}
                                              onChange={this.onFieldChange.bind(this)}
                                              placeholder="Zugeordnete Aufgabe"/>
                                </div>
                            </div>
                        </Dialog>

                        {
                            this.props.hours.length > 0 ?
                                <DataTableMobile

                                    rowClassName={(row) => row.type}
                                    value={this.getConversations()}>

                                    <Column
                                        field={"iss"}
                                        style={{width: "10em"}}
                                        header={"Wann"}
                                        body={(r, c) => {

                                            var now = moment(new Date()); //todays date
                                            var end = moment(r.iss); // another date

                                            var duration = moment.duration(-now.diff(end));

                                            moment.locale('de')

                                            let wann = duration.humanize(true)

                                            if (r.type == "ticket")
                                                return <>{wann} <a target={"_blank"} className={"p-button"} style={{float:"right",marginTop:-6}}
                                                          href={"https://helpdesk.ziegenhagel.com/admin/admin_ticket.php?track=" + r.trackid}>
                                                    <i className="pi pi-tag" style={{position:"absolute"}}></i> &emsp;&emsp;&emsp;&emsp;    {r.trackid}
                                                </a></>

                                            return wann

                                        }}/>
                                    <Column style={{"white-space": "pre-line"}} field={"text"} header={"Tätigkeit"}
                                            body={(r, c) => {
                                                return <ReadMoreDiv text={r.text} type={r.type}/>
                                            }}/>

                                    {/**
                                     <Column style={{width: "10em"}} field={"uid"} header={"Wer"}
                                     body={(rowData, column) => {
                                                for (let user of this.props.users) {
                                                    if (user.id === rowData.uid)
                                                        return user.fname
                                                }
                                                return "- - -"
                                            }}/>
                                     **/}

                                    <Column style={{width: "8em", textAlign: "center"}} body={(rowData, column) => {

                                        // if rowData.type is ticket, show the trackid
                                        if (rowData.type === "ticket") {

                                            let attachments = rowData.attachments.split(",")
                                            if(attachments.length > 0) attachments.pop()
                                            return <>

                                                {attachments.map((attachment) =>
                                                    <a target={"_blank"}
                                                       className={"p-button p-component p-button-primary"}
                                                       href={"https://helpdesk.ziegenhagel.com/download_attachment.php?track=" + rowData.trackid + "&att_id=" + attachment}>
                                                        <i className="pi pi-file"></i>
                                                        {attachment.split("#")[1]}
                                                    </a>
                                                )}
                                            </>
                                        }
                                        return <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "20px"
                                        }}>
                                            <div>
                                                <Button
                                                    disabled={!this.props.api.hasPolicy("hours_edit") && !(rowData.uid === this.props.api.getUserfield("id") && rowData.ttl < 1500)}
                                                    onClick={() => { // change if younger than 25 minutes
                                                        this.setState({
                                                            dialogVisible: true,
                                                            currentAction: "edit",
                                                            currentFields: rowData
                                                        }, () => {
                                                            console.log(this.state)
                                                        })
                                                    }} type="button" icon="pi pi-pencil"
                                                    className="p-button-secondary"></Button>
                                                <Button
                                                    onClick={() => {
                                                        let btns_html = [1, 2, 3, 4, 5].map(rating => `<a class="btn" href="https://www.ziegenhagel.com/support/surveys/conversation/?id=${rowData.id}&rating_helpful=${rating}&rating_solved=-1">${rating}</a>`).join(' ')
                                                        window.prompt('Kund:innen Aufforderung zur Bewertung senden. (Diesen HTML Code in die Mail einfügen)',
                                                            `<div><br>- - -<br><br></div><div class=“feedback-rating”>Wie hilfreich war diese Antwort? <div style="margin:10px 0"> ${btns_html} </div> <div>Nicht hilfreich&emsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;Exzellent</div></div>`)
                                                    }} className="p-button-secondary" style={{marginLeft: 10}}
                                                    icon="pi pi-envelope"></Button>
                                                <Button disabled={!this.props.api.hasPolicy("hours_delete")}
                                                        onClick={() => {
                                                            this.setState({
                                                                confirmVisible: true,
                                                                confirmMessage: "Tätigkeit " + rowData.text + " löschen?",
                                                                confirmFun: () => {
                                                                    this.props.api.deleteHour(rowData.id)
                                                                        .then(r => {
                                                                            if (r.status === 200) {
                                                                                this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                                this.props.refreshHours()
                                                                            } else if (r.status == 420) {
                                                                                this.showError("Du darfst dieses Element nicht löschen.")
                                                                            } else {
                                                                                this.showError("Es ist ein Fehler aufgetreten.")
                                                                            }
                                                                        })
                                                                        .catch(r => this.showError("Konnte nicht gelöscht werden."))
                                                                }
                                                            })
                                                        }} type="button" style={{marginLeft: 10}} icon="pi pi-trash"
                                                        className="p-button-danger"></Button>
                                            </div>
                                            {rowData.rating_helpful != null &&
                                                <div> Survey: {rowData.rating_helpful} / {rowData.rating_solved} / {rowData.rating_usertext}</div>}
                                        </div>
                                    }}/>
                                </DataTableMobile>
                                :
                                <p>Keine Elemente gefunden.</p>
                        }


                    </div>
                    <div class="card">
                        <h1>Survey</h1>
                        <div className="subtitle">Hier kann der Projektablauf am Schluss durch die Kund:in bewertet
                            werden.
                        </div>
                        {
                            !this.props.surveys?.project?.data ?
                                <InputText
                                    value={"https://ziegenhagel.com/support/surveys/project/?pid=" + this.props.pid}/>
                                :
                                <div>
                                    <table className={"table table-striped"}>
                                        {
                                            this.getSurveyProjectRatings_toArray().map((d, i) => {
                                                    return <tr>

                                                        <td>{d.question}
                                                            <div
                                                                style={{opacity: .5}}>{this.getSurveyProjectStructure().find(s => s.slug === d.question)?.question}
                                                            </div>
                                                        </td>

                                                        <td style={{textAlign: "right"}}>{this.getSurveyProjectStructure().find(s => s.slug === d.question)?.term1}</td>
                                                        <td style={{textAlign: "center"}}>{[1, 2, 3, 4, 5].map(no => no === d.rating ?
                                                            <b>{no}</b> : <span style={{opacity: .5}}>{no}</span>)}</td>
                                                        <td>{this.getSurveyProjectStructure().find(s => s.slug === d.question)?.term5}</td>
                                                        <td>{d.usertext}</td>
                                                    </tr>
                                                }
                                            )
                                        }
                                    </table>
                                    <p>
                                        Durchführung: {this.props.surveys.project.iat},
                                        Zeit Gutschrift: {this.props.surveys.project.mins} min
                                    </p>
                                </div>
                        }
                    </div>
                    <div class="card">
                        <h1>Fahrten</h1>

                        <div className="subtitle">Dies ist eine reduzierte Darstellung <Link to="/hours">aller
                            Tätigkeiten</Link>. Als Fahrten zählen alle Tätigkeiten, die einen Ort bei "Adresse"
                            eingetragen haben.
                        </div>


                        {
                            this.props.hours.length > 0 ?
                                <DataTable value={this.props.hours.filter(h => h.address !== "" && h.address !== null)}>

                                    <Column field={"iss"} style={{width: "15em"}} header={"Wann"} body={(r, c) => {

                                        return r.iss
                                    }}/>
                                    <Column field={"iss"} style={{width: "15em"}} header={"Wo"} body={(r, c) => {
                                        return r.address
                                    }}/>
                                    <Column field={"iss"} header={"Was"} body={(r, c) => {
                                        return <ReadMoreDiv text={r.text} type={r.type}/>
                                    }}/>
                                </DataTable> :
                                <div style={{padding: 20, opacity: .5, textAlign: "center"}}>Keine Elemente
                                    gefunden.</div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    getConversations() {
        let hourSelection = this.props.hours.filter(hours => (hours.euros === "0" && hours.mins === "0") || hours.text.includes("elefon") || hours.text.includes("undenkontakt"))

        let elementRegistry = hourSelection.map(obj => ({iss: obj.iss, type: 'hour', obj}))

        // add all this.props.tickets to elementRegistry
        this.props.tickets.forEach(ticket => {
            elementRegistry.push({iss: ticket.dt, type: 'ticket', obj: ticket})
        })

        // now sort by iss
        elementRegistry.sort((a, b) => {
            return new Date(b.iss.replace(" ","T")) - new Date(a.iss.replace(" ","T"))
        })

        // now create the conversations
        let conversations = []

        elementRegistry.forEach(({obj, type}, i) => {
            // if its a type hour, just add it to conversations
            if (type === 'hour') {
                conversations.push({...obj, type: 'hour'})
            }
            // if its a type ticket, convert it to a conversation
            else if (type === 'ticket') {
                let conversation = {
                    id: 0,
                    text: obj.subject + "\r\n" + obj.message,
                    uid: "-1",
                    pid: "-1",
                    iss: obj.dt,
                    mins: "0",
                    euros: "0",
                    tid: "0",
                    address: null,
                    rating_helpful: null,
                    rating_solved: null,
                    rating_usertext: null,
                    status: null,
                    ttl: -1,
                    type: "ticket",
                    trackid: obj.trackid,
                    attachments: ""+obj.attachments,
                }

                conversations.push(conversation)
            }
        })

        return conversations

    }
}
