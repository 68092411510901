import React, { Component } from 'react';
import { DataTable, Column } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';

export class ExpensesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false,
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => { console.log("not implemented") },
            currentFields: { title: "", sex: "", address1: "", address2: "", email: "", phone: "", iss: "", fname: "", lname: "", uid: 0 },
            currentAction: "insert",
        };
    }

    onFieldChange(e) {
        let currentFields = this.state.currentFields
        currentFields[e.target.name] = e.target.value
        this.setState({ currentFields })
    }
    showSuccess(detail) {
        this.growl.show({ closable: true, severity: 'success', summary: 'Erfolgreich durchgeführt!', detail });
    }

    showError(detail) {
        this.growl.show({ closable: true, severity: 'error', summary: 'Fehler!', detail });
    }


    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                        <h1>Ausgaben</h1>
                        <div className="header-actions">
                            <Button disabled={!this.props.api.hasPolicy("expenses_create")} icon="pi pi-plus" onClick={() => { this.setState({ currentAction: "insert", currentFields: { title: "", sex: "", address1: "", address2: "", email: "", phone: "", iss: "", fname: "", lname: "", uid: 0 }, dialogVisible: true }) }} label="Hinzufügen" />
                        </div>

                        <Dialog style={{ textAlign: "center" }} header="Fortfahren?" visible={this.state.confirmVisible} modal={true} onHide={() => this.setState({ confirmVisible: false })}
                            footer={<div>
                                <Button onClick={() => { this.setState({ confirmVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                                <Button onClick={() => { this.state.confirmFun(); this.setState({ confirmVisible: false }) }} label="Fortfahren" className="p-button-primary" icon="pi pi-check" />
                            </div>} >{this.state.confirmMessage}</Dialog>

                        <Dialog header={
                            this.state.currentAction === "insert" ?
                                "Hinzufügen" : "Bearbeiten"
                        } visible={this.state.dialogVisible} modal={true} onHide={() => this.setState({ dialogVisible: false })}
                            footer={<div>
                                <Button onClick={() => { this.setState({ dialogVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                                <Button onClick={() => {
                                    if (this.state.currentAction === "insert") {
                                        // add the expense
                                        this.props.api.insertExpense(this.state.currentFields)
                                            .then((r) => {
                                                this.setState({ dialogVisible: false })
                                                this.showSuccess("Gespeichert.")
                                                this.props.refreshExpenses()
                                            })
                                            .catch((e) => {
                                                this.showError("Konnte nicht erstellt werden.")
                                            })
                                    } else {
                                        // update the expense
                                        this.props.api.updateExpense(this.state.currentFields)
                                            .then((r) => {
                                                this.setState({ dialogVisible: false })
                                                this.showSuccess("Änderungen gespeichert.")
                                                this.props.refreshExpenses()
                                            })
                                            .catch((e) => {
                                                this.showError("Speichern der Änderungen fehlgeschlagen.")
                                            })
                                    }
                                }} label={this.state.currentAction === "insert" ? "Hinzufügen" : "Speichern"} icon="pi pi-check" />
                            </div>}
                            className="form"
                        >
                            <div>
                                <InputText name="title" value={this.state.currentFields.title} onChange={this.onFieldChange.bind(this)} placeholder={`Titel (z.b: "Dr. ","Dr. Prof.") [mit Leerzeichen endend]`} />
                                <InputText name="fname" value={this.state.currentFields.fname} onChange={this.onFieldChange.bind(this)} placeholder="Vorname" />
                                <InputText name="lname" value={this.state.currentFields.lname} onChange={this.onFieldChange.bind(this)} placeholder="Nachname" />
                                <InputText name="address1" value={this.state.currentFields.address1} onChange={this.onFieldChange.bind(this)} placeholder="Strasse & Hausnummer" />
                                <InputText name="address2" value={this.state.currentFields.address2} onChange={this.onFieldChange.bind(this)} placeholder="Ort & PLZ" />
                                <InputText name="email" value={this.state.currentFields.email} onChange={this.onFieldChange.bind(this)} placeholder="E-mail" />
                                <InputText name="phone" value={this.state.currentFields.phone} onChange={this.onFieldChange.bind(this)} placeholder="Telefonnummer" />
                            </div>
                        </Dialog>

                        {
                            this.props.expenses.length > 0 ?
                                <DataTable value={this.props.expenses}>
                                    <Column field={"id"} header={"ID"} style={{ width: "3em", textAlign: "center" }} />
                                    <Column field={"description"} header={"Beschreibung"} />
                                    <Column field={"euros"} header={"Summe"} body={(r,c)=>parseFloat(r.euros).toFixed(2)+"€"}/>
                                    <Column field={"iss"} header={"Datum"} />
                                    <Column field={"uid"} header={"Ansprechpartner*in"} body={(row,col)=>{
                                        return this.props.users.map(user => {
                                            if(user.id == row.uid)
                                                return user.fname+" "+user.lname;
                                        }) 
                                    }} />
                                    <Column header={"Aktionen"} style={{ width: "13em", textAlign: "center" }} body={(rowData, column) => {
                                        return <div>
                                            
                                            <Button disabled={!this.props.api.hasPolicy("expenses_edit")} onClick={() => {
                                            }} icon="pi pi-check" className="p-button-success p-button-labelicon" iconPos="left" label="Absetzbar"></Button>

                                            <Button disabled={!this.props.api.hasPolicy("expenses_edit")} onClick={() => {
                                            }} icon="pi pi-check" className="p-button-primary p-button-labelicon" iconPos="left" label="Teilw. absetzbar"></Button>

                                            <Button disabled={!this.props.api.hasPolicy("expenses_edit")} onClick={() => {
                                            }} icon="pi pi-times" className="p-button-secondary p-button-labelicon" iconPos="left" label="Nicht absetzbar"></Button>

                                            <div className="divider"/>

                                            <Button disabled={!this.props.api.hasPolicy("expenses_edit")} onClick={() => {
                                                this.setState({ dialogVisible: true, currentAction: "edit", currentFields: rowData }, () => { console.log(this.state) })
                                            }} type="button" icon="pi pi-pencil" className="p-button-secondary"></Button>
                                            <Button disabled={!this.props.api.hasPolicy("expenses_delete")} onClick={() => {
                                                this.setState({
                                                    confirmVisible: true,
                                                    confirmMessage: "Kunde*in " + rowData.fname + " " + rowData.lname + " löschen?",
                                                    confirmFun: () => {
                                                        this.props.api.deleteExpense(rowData.id)
                                                            .then(r => {
                                                                if (r.status == 200) {
                                                                    this.props.refreshExpenses()
                                                                    this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                } else if (r.status == 420) {
                                                                    this.showError("Du darfst dieses Element nicht löschen.")
                                                                } else {
                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                }
                                                            })
                                                            .catch(r => this.showError("Konnte nicht gelöscht werden."))
                                                    }
                                                })
                                            }} type="button" style={{ marginLeft: 10 }} icon="pi pi-trash" className="p-button-danger"></Button>
                                        </div>
                                    }} />
                                </DataTable>
                                :
                                <p>Keine Elemente gefunden.</p>
                        }

                    </div>
                </div>
            </div>
        );
    }
}
