import React, { Component } from 'react';
import { DataTable, Column } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import Api,{templateQuotes} from '../Api';
import config from "../config";

export class QuotesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false,
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => { console.log("not implemented") },
            autoChange: true,
            pid: 0,
            quotes: [],
            template: null

        };
    }

    onFieldChange(e, index, findex = undefined) {
        let currentFields = this.state.quotes[index]
        if (findex === undefined)
            currentFields[e.target.name] = e.target.value
        else {
            if (e.target.name === "euros")
                currentFields.fixeds[findex][e.target.name] = parseInt(e.target.value)
            else
                currentFields.fixeds[findex][e.target.name] = e.target.value
        }
        this.setState({ currentFields })
    }

    componentDidMount() {
        this.refreshQuotes()
    }

    showSuccess(detail) {
        this.growl.show({ closable: true, severity: 'success', summary: 'Erfolgreich druchgeführt!', detail });
    }

    showError(detail) {
        this.growl.show({ closable: true, severity: 'error', summary: 'Fehler!', detail });
    }

    refreshQuotes() {
        this.setState({ pid: this.props.pid }, () => {
            this.props.api.getQuotes(this.props.pid).then(d => d.json())
                .then(d => this.setState({ quotes: d.data }))
                .catch(e => console.log(e))
        })
    }

    removeFixed(index, findex) {
        let quotes = this.state.quotes
        quotes[index].fixeds.splice(findex, 1)
        this.setState({
            quotes
        })
    }

    render() {
        if (this.state.pid !== this.props.pid)
            this.refreshQuotes()
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                        <Dialog style={{ textAlign: "center" }} header="Fortfahren?" visible={this.state.confirmVisible} modal={true} onHide={() => this.setState({ confirmVisible: false })}
                            footer={<div>
                                <Button onClick={() => { this.setState({ confirmVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                                <Button onClick={() => { this.state.confirmFun(); this.setState({ confirmVisible: false }) }} label="Fortfahren" className="p-button-primary" icon="pi pi-check" />
                            </div>} >{this.state.confirmMessage}</Dialog>

                        <h1>Angebote</h1>
                        <div className="header-actions">
                            { this.state.quotes.filter(q=>q.status == "accepted").length == 0 &&

                                <>
                                    {
                                        this.state.template != null && <Dropdown onChange={(e) => {
                                            console.log("onChange", e.target.value);
                                            this.setState({ template: e.target.value })
                                        }} value={this.state.template} options={templateQuotes[this.props.project.type]} />
                                    }
                                    <Button icon={ this.state.template == null ? "pi pi-chart-bar" : "pi pi-step-forward"} onClick={() => {

                                        if (this.state.template == null)
                                            this.state.template = templateQuotes[this.props.project.type][0].value
                                        else
                                            this.props.api.createTemplateQuotes(this.props.pid,this.state.template).then(d => {
                                                this.setState({ template: null})
                                                this.showSuccess("Angebote wurden nach Vorlage erstellt.");
                                                this.refreshQuotes();
                                            });

                                    }} /></>}
                            <Button disabled={!this.props.api.hasPolicy("quotes_create")} icon="pi pi-plus" onClick={() => {
                                this.props.api.insertQuote(this.props.pid)
                                    .then(d => {
                                        this.showSuccess("Angebot wurde erstellt.")
                                        this.refreshQuotes()
                                    })
                                    .catch(d => this.showError("Angebot konnte nicht erstellt werden."))
                            }} label="Hinzufügen" />
                        </div>

                    </div>
                    <div style={{ marginTop: -7, background: "#EDF0F5", paddingTop: 5 }}>
                        <div className="quotes">
                            {
                                this.state.quotes.map((quote, index) =>
                                    this.props.api.hasPolicy("quotes_edit") ?
                                        <Card className="quote">
                                            <h2 style={{ textAlign: "center", borderBottom: "1px solid #9994", marginBottom: 10, paddingBottom: 10 }}>
                                                Angebot AG{quote.id}
                                                <span style={{ marginLeft: 5, fontSize: ".8em" }}>
                                                    {quote.status === "accepted" ? <span style={{ color: "#0a0" }}>angenommen</span> : <span style={{ color: "orangered" }}>ausstehend</span>}
                                                </span>
                                            </h2>
                                            <div className="p-inputgroup header-wrapper">
                                                <Button icon="pi pi-wifi" className="p-button-secondary" onClick={() => {

                                                    /* PIN SEND SOCKET */
                                                    let data = { pin:window.pin,type:'quote',
                                                        value: {...quote }
                                                    }

                                                    console.log("Socket emit", data)

                                                    fetch('https://overtime-socket.onrender.com/payload', {
                                                        headers: {"content-type": "application/json"},
                                                        method: "post", body: JSON.stringify(data)
                                                    }).then(d => d.json()).then(d => {
                                                        if (!d.succes) window.alert(' Fehler')
                                                    })


                                                }} />
                                                <Button icon="pi pi-download" onClick={() => {
                                                    window.open(config.api_url+"/pdf/?pid=" + this.props.pid + "&id=" + quote.id + "&type=quote", "_blank")
                                                }} />
                                                <Button icon="pi pi-check" onClick={() => {
                                                    this.setState({
                                                        confirmVisible: true, confirmMessage: "Dieses Angebot annehmen?", confirmFun: () => {
                                                            this.props.api.updateQuote({ id: quote.id, status: "accepted" })
                                                                .then(d => this.refreshQuotes())
                                                                .catch(e => this.showError("Konnte Angebot nicht annehmen."))
                                                            this.props.api.acceptQuote(quote.id)
                                                                .then(d => console.log(d))
                                                                .catch(e => this.showError("Konnte Tätigkeiten nicht erstellen."))
                                                            this.props.refreshProject()
                                                        }
                                                    })
                                                }} disabled={!this.props.api.hasPolicy("quotes_edit") || quote.status === "accepted"} className="p-button-success" />
                                                <InputText disabled={!this.props.api.hasPolicy("quotes_edit") || quote.status === "accepted"} name="title" onBlur={() => {
                                                    this.props.api.updateQuote({ id: quote.id, title: quote.title })
                                                }} onChange={(e) => { this.onFieldChange(e, index) }} className="header" value={quote.title} />
                                                <span className="p-inputgroup-addon" style={{ width: "70px" }}>
                                                    {
                                                        quote.fixeds.reduce((sum, fixed) => parseInt(fixed.euros) + sum, 0) + "€"
                                                    }
                                                </span>
                                                {this.props.api.hasPolicy("quotes_edit") && <>
                                                    <Button icon="pi pi-clone" className="p-button-secondary" onClick={() => {
                                                        this.props.api.copyQuote(quote.id)
                                                            .then(d => this.refreshQuotes())
                                                            .catch(e => this.showError("Konnte Angebot nicht kopieren."))
                                                    }} />
                                                    <Button icon="pi pi-bolt" className="p-button-secondary" onClick={() => {
                                                        this.props.api.recalcQuote(quote.id)
                                                            .then(d => this.refreshQuotes())
                                                            .catch(e => this.showError("Konnte Angebot nicht recalcen."))
                                                    }} />
                                                    <Button disabled={quote.status === "accepted"} onClick={() => {
                                                        this.setState({
                                                            confirmVisible: true, confirmMessage: "Dieses Angebot löschen?", confirmFun: () => {
                                                                this.props.api.deleteQuote(quote.id)
                                                                    .then(d => this.refreshQuotes())
                                                                    .catch(e => this.showError("Konnte Angebot nicht löschen."))
                                                            }
                                                        })
                                                    }} icon="pi pi-trash" className="p-button-danger" />
                                                </>}
                                            </div>
                                            <Button disabled={quote.status === "accepted"} icon="pi pi-plus" onClick={() => {
                                                let quotes = this.state.quotes
                                                this.props.api.updateQuoteHasFixed(quote.id, { text: "", euros: 0 })
                                                    .then(d => this.refreshQuotes())
                                                    .catch(e => console.log(e))
                                            }} className="fixed-add p-button-secondary" />
                                            {
                                                quote.fixeds.map((fixed, findex) =>
                                                    <div className="quote-fixed">
                                                        <div className="p-inputgroup">
                                                            <InputText disabled={!this.props.api.hasPolicy("quotes_edit") || quote.status === "accepted"} onBlur={() => {
                                                                console.log(fixed)
                                                                this.props.api.updateQuoteHasFixed(quote.id, fixed)
                                                                    .then(d => this.refreshQuotes())
                                                                    .catch(e => this.showError("Konnte Festpreis nicht speichern"))
                                                            }} name="euros" onChange={(e) => {
                                                                this.onFieldChange(e, index, findex)
                                                            }} placeholder="Preis" value={fixed.euros} />
                                                            <span className="p-inputgroup-addon">€</span>
                                                        </div>
                                                        <div className="p-inputgroup">
                                                            <InputText disabled={!this.props.api.hasPolicy("quotes_edit") || quote.status === "accepted"} onBlur={() => {
                                                                console.log(fixed)
                                                                this.props.api.updateQuoteHasFixed(quote.id, fixed)
                                                                    .then(d => this.refreshQuotes())
                                                                    .catch(e => this.showError("Konnte Festpreis nicht speichern"))
                                                            }} name="text" onChange={(e) => {
                                                                this.onFieldChange(e, index, findex)
                                                            }} value={fixed.text} />

                                                            {this.props.api.hasPolicy("quotes_edit") && <>
                                                                <Button icon="pi pi-clone" disabled={!this.props.api.hasPolicy("quotes_edit")} className="p-button-secondary" onClick={() => {
                                                                    for (let i = index + 1; i < this.state.quotes.length; i++) {
                                                                        let quote = this.state.quotes[i]
                                                                        this.props.api.updateQuoteHasFixed(quote.id, { text: fixed.text, euros: fixed.euros })
                                                                            .then(d => this.refreshQuotes())
                                                                            .catch(e => console.log("e", e))
                                                                    }
                                                                }} />
                                                                <Button disabled={!this.props.api.hasPolicy("quotes_edit") || quote.status === "accepted"} onClick={() => {
                                                                    this.removeFixed(index, findex);
                                                                    this.props.api.deleteQuoteHasFixed(fixed.id)
                                                                        .then(e => console.log(e))
                                                                        .catch(e => this.showError("Konnte Festpreis nicht löschen."))
                                                                }} icon="pi pi-trash" className="p-button-danger" />
                                                            </>}
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </Card>
                                        :
                                        <Card className="quote">
                                            <div className="header-wrapper">
                                                <h2 style={{ textAlign: "center", borderBottom: "1px solid #aaa2", marginBottom: 10, paddingBottom: 10 }}>
                                                <Button className="p-button-secondary" icon="pi pi-download" style={{float:"left"}} onClick={() => {
                                                    window.open(config.api_url+"/pdf/?pid=" + this.props.pid + "&id=" + quote.id + "&type=quote", "_blank")
                                                }} />
                                                    Angebot AG{quote.id}
                                                    <span style={{ marginLeft: 5, fontSize: ".8em" }}>
                                                        {quote.status === "accepted" ? <span style={{ color: "#0a0" }}>angenommen</span> : <span style={{ color: "orangered" }}>ausstehend</span>}
                                                    </span>
                                                </h2>
                                                <table className="table">
                                                    {quote.fixeds.map((fixed, findex) => {
                                                        return <tr><td>{fixed.text}</td><td style={{ textAlign: "right", width: "4em" }}>{fixed.euros}€</td></tr>
                                                    })}
                                                </table>
                                            </div>
                                        </Card>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
