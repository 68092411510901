import config from "./config";

class Api {


    auth(body) {
        return this.call("auth", "POST", body)
    }

    getExpenses() {
        return this.call("expenses", "GET")
    }

    insertExpense({fname, lname, email, pass}) {
        return this.call("expense", "POST", {fname, lname, email, pass})
    }

    updateExpense({id, fname, lname, email, pass, policies}) {
        return this.call("expense/" + id, "PUT", {fname, lname, email, pass, policies})
    }

    deleteExpense(id) {
        return this.call("expense/" + id, "DELETE")
    }


    sendEventConfirmation(pid, body) {
        return this.call("project/" + pid + "/event", "POST", body)
    }

    sendInfomail(pid, body) {
        return this.call("project/" + pid + "/infomail", "POST", body)
    }

    get2FA() {
        return this.call("2fa", "GET")
    }


    getInitial() {
        return this.call("initial", "GET")
    }


    getUsers() {
        return this.call("users", "GET")
    }

    insertUser({fname, lname, email, pass}) {
        return this.call("user", "POST", {fname, lname, email, pass})
    }

    updateUser({id, recent_pid, fname, lname, email, pass, policies, slack_id}) {
        return this.call("user/" + id, "PUT", {recent_pid, fname, lname, email, pass, policies, slack_id})
    }

    deleteUser(id) {
        return this.call("user/" + id, "DELETE")
    }


    getProjects() {
        return this.call("projects", "GET")
    }

    getProject(id) {
        return this.call("project/" + id, "GET")
    }

    insertProject({budget, title, slug, cid, uid, type, status, url}) {
        return this.call("project", "POST", {budget, title, slug, cid, uid, type, status, url})
    }

    updateProjectUrl({id, url}) {
        return this.call("project/" + id, "PUT", {url})
    }

    updateProject({id, budget, title, slug, cid, uid, type, status, care, url}) {
        return this.call("project/" + id, "PUT", {budget, title, slug, cid, uid, type, status, care, url})
    }

    deleteProject(id) {
        return this.call("project/" + id, "DELETE")
    }

    // plug review 
    createReviewVideo({title, pid, url}) {
        return this.call("plug-review/" + pid + "/videos", "POST",
            {title, url})
    }

    createReviewVersion({changelog, pid, video_id, url}) {
        return this.call("plug-review/" + pid + "/versions", "POST",
            {changelog, video_id, url})
    }

    getReviewVideos(pid) {
        return this.call("plug-review/" + pid + "/videos", "GET")
    }

    getCustomers() {
        return this.call("customers", "GET")
    }

    insertCustomer({company, url, title, sex, fname, lname, phone, email, address1, address2}) {
        return this.call("customer", "POST", {company, url, title, sex, fname, lname, phone, email, address1, address2})
    }

    updateCustomer({status, company, url, id, title, sex, fname, lname, phone, email, address1, address2}) {
        return this.call("customer/" + id, "PUT", {
            status,
            company,
            url,
            title,
            sex,
            fname,
            lname,
            phone,
            email,
            address1,
            address2
        })
    }

    deleteCustomer(id) {
        return this.call("customer/" + id, "DELETE")
    }

    deleteLeads() {
        if (window.confirm("Alle Leads löschen?"))
            return this.call("customer/0", "DELETE")
        else
            return new Promise((d) => {
            });
    }


    insertSubscription({pid, plan_id}) {
        return this.call("project/" + pid + "/subscription", "POST", {plan_id})
    }

    updateSubscription({id, pid, auto_renewal}) {
        return this.call("project/" + pid + "/subscription", "PUT", {id, auto_renewal})
    }

    getWages(pid) {
        return this.call("wages/" + pid, "GET")
    }

    deleteWage({pid, uid}) {
        return this.call("wage/" + pid + "/" + uid, "DELETE")
    }

    insertWage({pid, uid, wage}) {
        return this.call("wage/" + pid + "/" + uid, "POST", {wage})
    }

    deleteHour(id) {
        return this.call("project/0/hour/" + id, "DELETE")
    }

    deleteHourCron(id) {
        return this.call("project/0/hourcron/" + id, "DELETE")
    }

    updateHour(body) {
        body.ttl = undefined;
        body.status = undefined;
        return this.call("project/0/hour/" + body.id, "PUT", body)
    }

    insertHour(pid, body) {
        return this.call("project/" + pid + "/hour", "POST", body)
    }

    insertHourCron(pid, body) {
        return this.call("project/" + pid + "/hourcron", "POST", body)
    }

    getHours(pid) {
        return this.call("project/" + pid + "/hours", "GET")
    }

    getHourCrons(pid) {
        return this.call("project/" + pid + "/hourcrons", "GET")
    }

    deleteTask(id) {
        return this.call("project/0/task/" + id, "DELETE")
    }

    updateTask(id, body) {
        return this.call("project/0/task/" + id, "PUT", body)
    }

    insertTask(pid, body) {
        return this.call("project/" + pid + "/task", "POST", body)
    }


    getQuotes(pid) {
        return this.call("project/" + pid + "/quotes", "GET")
    }

    acceptQuote(qid) {
        return this.call("project/0/quote/" + qid + "/accept", "POST")
    }

    copyQuote(qid) {
        return this.call("project/0/quote/" + qid + "/copy", "POST")
    }

    recalcQuote(qid) {
        return this.call("project/0/quote/" + qid + "/recalc", "POST")
    }

    updateQuote(body) {
        return this.call("project/0/quote/" + body.id, "PUT", body)
    }

    deleteQuote(id) {
        return this.call("project/0/quote/" + id, "DELETE")
    }

    insertQuote(pid) {
        return this.call("project/" + pid + "/quote", "POST", {})
    }

    createTemplateQuotes(pid, template) {
        return this.call("project/" + pid + "/templatequotes", "POST", {template})
    }

    deleteQuoteHasFixed(id) {
        return this.call("project/0/quote/0/fixed/" + id, "DELETE")
    }

    updateQuoteHasFixed(qid, body) {
        console.log("updateQuoteHasFixed from Api.js", body)
        body.euros = "" + body.euros;
        if (body.id === undefined)
            return this.call("project/0/quote/" + qid + "/fixed", "POST", body)
        else
            return this.call("project/0/quote/0/fixed/" + body.id, "PUT", body)
    }

    deleteInvoice(id) {
        return this.call("project/0/invoice/" + id, "DELETE")
    }

    updateInvoice(id, body) {
        return this.call("project/0/invoice/" + id + "", "PUT", body)
    }

    insertInvoice(pid, body) {
        return this.call("project/" + pid + "/invoice", "POST", body)
    }

    getInvoices(pid) {
        return this.call("project/" + pid + "/invoices", "GET")
    }

    insertInstallment(pid, body) {
        return this.call("project/" + pid + "/installment", "POST", body)
    }


    insertInvoiceCron(pid, body) {
        return this.call("project/" + pid + "/invoicecron", "POST", body)
    }

    deleteInvoiceCron(pid, body) {
        return this.call("project/" + pid + "/invoicecron", "DELETE", body)
    }

    insertApikey() {
        return this.call("apikey/0/create", "GET")
    }

    call(resource, method, body) {
        
        const jwt = this.accessToken
        // check if token is expired
        if (jwt != null) {
            const jwtParts = jwt.split(".")
            const jwtPayload = JSON.parse(atob(jwtParts[1]))
            const now = Math.floor(Date.now() / 1000)
            if (now > jwtPayload.exp) {
                // token is expired
                if(window.confirm("Your session has expired. Would you like to login again?")) {
                    this.accessToken = null
                    window.location.href = "/"
                }
            }
        }

        this.handleURIToken()
        this.setLoaderEnabled(true)
        return fetch(config.api_url + resource, {
            method,
            headers: {
                "Authorization": "Bearer " + this.accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).finally(() => {
            setTimeout(() => {
                this.setLoaderEnabled(false)
            }, 30)
        })
    }

    handleURIToken() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('dialogflow_connector_token')

        if (token == "" || token == null) return

        console.log("found token, handling it", token)
        fetch(config.api_url+"/2fa", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + this.accessToken,
                "Handle-token": token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"action": "handle_token"})
        })
            .then(d => d.json())
            .then(d => {
                if (d.token_handled) {
                    // token was handled
                    alert("Vielen Dank, Du kannst die Seite jetzt wieder schliessen und zurückkehren.")
                    window.history.replaceState({}, document.title, "/#/hours");
                }
            })
            .catch(e => {
                console.error("Handle_token",e)
            })
    }

    getUser() {
        return this.call("user", "GET")
    }

    getUserfield(key) {
        return (this.user !== undefined && this.user[key] !== undefined) ? this.user[key] : null
    }

    constructor({accessToken, setAccessToken}, cb_when_ready, cb_when_error, setLoaderEnabled) {
        this.setLoaderEnabled = setLoaderEnabled
        this.accessToken = accessToken
        this.setAccessToken = setAccessToken
        this.getUser()
            .then(d => d.json())
            .catch(e => {
                cb_when_error()
                console.error("LOGIN JSON", e)
            })
            .then(r => {
                this.user = r.data
                cb_when_ready(this)
            })
            .catch(e => {
                console.error("LOGIN ER", e)
            })
            .finally(() => {
                //console.log(this.hasPolicy("users_list") ? "granted" : "denied")
            })
    }

    load(state) {
        this.user = state.user
        if (this.accessToken === undefined)
            this.accessToken = state.accessToken
    }

    setLoaderEnabled = (bool) => {
    }
    user = undefined
    accessToken = undefined
    setAccessToken = undefined

    hasPolicy(policy_tag) {

        if (policy_tag === undefined)
            return true;

        // example tag: user_list
        let t = policy_tag.split("_");
        let policies
        try {
            policies = JSON.parse(this.user.policies);
            if (policies === null)
                return false;
        } catch (e) {
            return false;
        }

        // check for $policies["*"]=="*"
        if (policies["*"] === "*") return true;

        if (Object.keys(policies).includes(t[0])) {

            // check for $policies["user"]=="*"
            if (policies[t[0]] === "*")
                return true;

            // check for $policies["user"][i]=="list"
            if (policies[t[0]].includes(t[1]))
                return true;
        }

        return false;
    }

    getTrelloCards(id) {
        this.setLoaderEnabled(true)
        return fetch("https://api.trello.com/1/boards/" + id + "/cards?key=a30f3ba724845cad3ff5063a0d2efa39&token=ac156155369c0e9097a618d870569d6ccac4357595328582356e4354157d32be", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(body)
        }).finally(() => {
            setTimeout(() => {
                this.setLoaderEnabled(false)
            }, 0)
        })
    }


    getTrelloLists(id) {
        this.setLoaderEnabled(true)
        return fetch("https://api.trello.com/1/boards/" + id + "/lists?key=a30f3ba724845cad3ff5063a0d2efa39&token=ac156155369c0e9097a618d870569d6ccac4357595328582356e4354157d32be", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(body)
        }).finally(() => {
            setTimeout(() => {
                this.setLoaderEnabled(false)
            }, 0)
        })
    }

    getTrelloBoards() {
        this.setLoaderEnabled(true)
        return fetch("https://api.trello.com/1/members/me/boards?key=a30f3ba724845cad3ff5063a0d2efa39&token=ac156155369c0e9097a618d870569d6ccac4357595328582356e4354157d32be", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(body)
        }).finally(() => {
            setTimeout(() => {
                this.setLoaderEnabled(false)
            }, 0)
        })
    }

}

export const projectStates = [
    {label: 'Entwurf', value: 'draft'},
    {label: 'Archiv', value: 'archive'},
    {label: 'Aktiv', value: 'active'},
    {label: 'Gelöscht', value: 'trash'},
];

export const projectTypes = [
    {label: 'Film (z.B. Imagefilm)', value: 'imagefilm'},
    {label: 'Programmierung (z.B. Webseite)', value: 'website'},
    {label: 'Anderer', value: 'other'},
];

export const customerStatus = [
    {label: 'Lead', value: 'lead'},
    {label: 'Active', value: 'active'},
    {label: 'Archive', value: 'archive'}
];

export const customerSex = [
    {label: 'Herr', value: 'm'},
    {label: 'Frau', value: 'w'}
];

export const projectCarePlans = [
    {label: "No Care", value: ""},
    {label: "Silber", value: "Silber"},
    {label: "Gold", value: "Gold"},
    {label: "Diamant", value: "Diamant"},
    {label: "Titan", value: "Titan"}];

export const templateQuotes = {
    website: [
        {label: "WordPress Webseitenerstellung", value: "wpsite"}
    ],
    imagefilm: [
        {label: "Videoproduktion 1-2 DT", value: "imagefilm"}
    ]
}

export default Api
