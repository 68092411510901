import React, {Component} from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Fieldset} from 'primereact/fieldset';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Toolbar} from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import config from "../config";

export class ReviewPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            videos: [],
            currentFields: {
                title: "",
                url: ""
            },
            versionFields: {
                changelog: "",
                url: ""
            }
        }
    }

    componentDidMount() {
        this.refreshVideos()
    }

    refreshVideos() {
        this.props.api.getReviewVideos(this.props.pid)
        .then(d=>d.json())
        .then(res=>{
            if(res.data)
                this.setState({videos:res.data.reverse()})
            console.log(res.data);
        })
    }

    onVersionFieldChange(e) {
        let versionFields = this.state.versionFields
        versionFields[e.target.name] = e.target.value
        this.setState({ versionFields })
    }
    onFieldChange(e) {
        let currentFields = this.state.currentFields
        currentFields[e.target.name] = e.target.value
        this.setState({ currentFields })
    }

    render() {

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Video hinzufügen</h1>
                        <div className="flexform">
                            <InputText name="url" style={{ flex: .3 }} type="url" value={this.state.currentFields.url} onChange={this.onFieldChange.bind(this)} placeholder="URL zu AWS S3 Bucket" />
                            <InputText name="title" style={{ flex: .6 }} value={this.state.currentFields.title} onChange={this.onFieldChange.bind(this)} placeholder="Titel des Videos (z.B. Imagefilm oder Reel1)" />
                            <Button icon="pi pi-plus" onClick={() => {
                                this.props.api.createReviewVideo({
                                    pid: this.props.pid,
                                    ...this.state.currentFields
                                }).then(res => {
                                    this.setState({
                                        currentFields: { ...this.state.currentFields, url: "", title: "" }
                                    })
                                    this.refreshVideos()
                                }).catch(e => {
                                    alert("Fehler")
                                })
                            }} label="Review Video anlegen"></Button>
                        </div>
                    </div>
                </div>

                {
                    this.state.videos.map(video => {
                        return <div className="p-col-12">
                                <div className="card card-w-title has-table">
                                    <h4>{video.title}</h4>
                                    <table class="table table-striped table-hover">
                                        { video.versions !== undefined && video.versions.map((version,index)=>{
                                            return <tr>
                                                <td width="110">Version {index+1}</td>
                                                <td><a target="_blank" href={config.review_url+"/?version_id="+version.id+"&brand="+(this.props.pid == 85 ? "guerillamedia":"ziegenhagel")}>{config.review_url+"/?version_id="+version.id}</a></td>
                                                <td width="520">{version.changelog}</td>
                                                <td>{version.iat}</td>
                                            </tr>
                                        })}
                                    </table>

                                <div className="flexform">
                                    <InputText name="url" type="url" style={{ flex: .2 }} value={this.state.versionFields.url} onChange={this.onVersionFieldChange.bind(this)} placeholder="URL zum AWS S3 Bucket" />
                                    <InputText name="changelog" style={{ flex: .8 }} value={this.state.versionFields.changelog} onChange={this.onVersionFieldChange.bind(this)} placeholder="Changelog" />
                                    <Button icon="pi pi-plus" onClick={() => {
                                        this.props.api.createReviewVersion({
                                            pid: this.props.pid,
                                            video_id: video.id,
                                            ...this.state.versionFields
                                        }).then(res => {
                                            this.setState({
                                                versionFields: { url: "", changelog: "" }
                                            })
                                            this.refreshVideos()
                                        }).catch(e => {
                                            alert("Fehler")
                                        })
                                    }} label="Version anlegen"></Button>
                                </div>
                            </div>
                        </div>
                    })

                }
            </div>
        );
    }
}
