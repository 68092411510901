import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return  (
            <div className="layout-footer">
                <span className="footer-text" style={{'marginRight': '5px',textAlign:"center"}}>&copy; Covo<span style={{opacity:.5}}>media 2019</span></span>
            </div>
        );
    }
}