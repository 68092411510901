import React, {Component} from 'react';
import {DataTable, Column} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Growl} from 'primereact/growl';
import {Dropdown} from 'primereact/dropdown';
import {InputTextarea} from 'primereact/inputtextarea';
import moment from 'moment'
import 'moment/locale/de'  // without this line it didn't work

class ReadMoreDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {readMoreEnabled: false}
    }

    render() {
        if (this.props.text.split("\n").length < 2)
            return this.props.text
        return <div>
            <i style={{cursor: "pointer", float: "right"}}
               className={"pi pi-chevron-" + (!this.state.readMoreEnabled ? "down" : "up")}
               onClick={() => this.setState({readMoreEnabled: !this.state.readMoreEnabled})}/>
            {
                this.state.readMoreEnabled ? this.props.text : this.props.text.split('\n')[0]
            }
        </div>
    }
}

export class HoursPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false,
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => {
                console.log("not implemented")
            },
            currentFields: {text: "", mins: 0, euros: 0, tid: 0},
            currentTaskFields: {title: "", mins: 0, assignee_uid: 0, euros: 0, uid: 0, deadline: "", description: ""},
            currentAction: "insert",

            //hours: this.props.hours,
            //tasks: [],
            //wages: [],
            //pid: 0
        };
    }

    /*
    refreshWages() {
        // TODO remove
        return
        this.props.api.getWages(this.props.pid)
            .then(r => r.json())
            .then(r => this.setState({ wages: r.data }))
            .catch(e => console.log(e))
    }

    refreshHours() {
        // TODO remove
        return
        this.setState({ pid: this.props.pid }, () => {
            this.props.api.getHours(this.props.pid)
                .then(d => d.json())
                .then(d => {
                    this.setState({ hours: d.data, tasks: d.tasks })
                })
                .catch(e => this.showError("Konnte Stunden/Tasks nicht aktualisieren."))
            this.refreshWages()
        })
    }
*/

    getWage(mins, uid) {
        for (let wage of this.props.wages) {
            if (parseInt(wage.uid) === parseInt(uid) && parseInt(wage.pid) === parseInt(this.props.pid)) {
                return (mins * parseInt(wage.wage) / 60).toFixed(2)
            }
        }
        return 0
    }

    onTaskFieldChange(e) {
        let currentTaskFields = this.state.currentTaskFields
        if (e.target.name === "mins" && this.state.currentAction === "task_create") {
            e.target.value = parseInt("0" + e.target.value)
            currentTaskFields.euros = this.getWage(e.target.value, currentTaskFields.assignee_uid)
        }
        currentTaskFields[e.target.name] = e.target.value
        this.setState({currentTaskFields})
    }

    onFieldChange(e) {
        let currentFields = this.state.currentFields
        if (e.target.name === "mins" && this.state.currentAction === "insert") {
            e.target.value = parseInt("0" + e.target.value)
            currentFields.euros = this.getWage(e.target.value, this.props.api.getUserfield("id")) // TODO replace own
        }
        currentFields[e.target.name] = e.target.value
        this.setState({currentFields})
    }

    showSuccess(detail) {
        this.growl.show({closable: true, severity: 'success', summary: 'Erfolgreich druchgeführt!', detail});
    }

    showError(detail) {
        if (this.growl !== null)
            this.growl.show({closable: true, severity: 'error', summary: 'Fehler!', detail});
        else
            console.error("couldnt show error: " + detail)
    }

    render() {
        if (this.props.pid !== this.props.pid)
            this.refreshHours()
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                    {/*
                    <div className="card">
                        <h1>Aufgaben</h1>
                        <div className="header-actions">
                            <Button disabled={!this.props.api.hasPolicy("tasks_create")} icon="pi pi-plus" onClick={() => { this.setState({ currentAction: "task_create", currentTaskFields: { pid: this.props.pid, description: "", deadline: "+7 days", assignee_uid: 0, title: "", uid: 0, euros: 0, mins: 0 }, dialogVisible: true }) }} label="Hinzufügen" />
                        </div>
                        { this.props.trello !== undefined && this.props.trello.cards !== undefined && this.props.trello.cards.length > 0 && <h2>Trello Aufgaben</h2> }
                        {
                            this.props.trello !== undefined &&
                            this.props.trello.lists.map(l=>{
                                if(this.props.trello.cards !== undefined && this.props.trello.cards.filter(c=>{return c.idList === l.id}).length > 0 )
                                   return <div className="small-list">
                                    <h3>{l.name}</h3>
                                    {
                                        this.props.trello.cards !== undefined &&
                                        this.props.trello.cards.map(c=>{
                                            if(c.idList === l.id) {
                                                return <li><a href={c.shortUrl} target="_blank">{c.name}</a></li>
                                            }
                                        })

                                    }
                                    </div> 
                            })
                        }
                        { this.props.trello !== undefined && <h2>Overtime Aufgaben</h2> }
                        {
                            this.props.tasks.filter(task=>parseInt(task.checked) === 0).length > 0 ?
                            <div>
                                <div className="subtitle">Eine Aufgabe fasst für mehrere notwendige Tätigkeiten zusammen, die an ein maximales Zeit und/oder Finanzbudget gebunden sein können.<br/> 
                                    Eine Aufgabe ist einem Benutzer mit einer Deadline zugeordnet.<br/>
                                    Ist eine Aufgabe abgeschlossen, so wird sie über den grünen Haken als erledigt gekennzeichnet und nicht mehr angezeigt.</div>
                                <DataTable value={this.props.tasks.filter(task=>parseInt(task.checked) === 0)}>
                                    <Column style={{ width: "10em" }} header={"Budget €"} body={(t) => {
                                        let used = this.props.hours.reduce((sum, hour) => hour.tid === t.id ? sum + parseFloat(hour.euros) : sum, 0)
                                        return <span class={"status-" + (used > t.euros ? "danger" : used > 0 && "success")}>{used + "€ / " + t.euros + "€"}</span>
                                    }} />
                                    <Column style={{ width: "10em" }} header={"Budget Zeit"} body={(t) => {
                                        let used = this.props.hours.reduce((sum, hour) => hour.tid === t.id ? sum + parseFloat(hour.mins) : sum, 0)
                                        return <span class={"status-" + (used > t.mins ? "danger" : used > 0 && "success")}>{used + " / " + t.mins + "min"}</span>
                                    }} />
                                    <Column field={"title"} header={"Aufgabe"} />
                                    <Column field={"uid"} header={"Wer?"} body={(rowData) => {

                                        var now = moment(new Date()); //todays date
                                        var end = moment(rowData.deadline); // another date

                                        var duration = moment.duration(-now.diff(end));

                                        var ms_til_deadline = (duration._milliseconds);

                                        moment.locale('de')

                                        let fontWeight = "inherit";
                                        let color = "#000";
                                        if(ms_til_deadline<0) {
                                            color = "#900";
                                            fontWeight = "bold";
                                        } else if (ms_til_deadline < 259200000) {
                                            color = "orangered";
                                        }

                                        for (let user of this.props.users) {
                                            if (user.id === rowData.assignee_uid)  {
                                                return <span style={{color,fontWeight}}>{user.fname+" ("+duration.humanize(true)+")"}</span>
                                            }
                                        }
                                        return <span className="muted">Nicht zugeordnet</span>
                                    }} />
                                    <Column style={{ width: "12em", textAlign: "center" }} body={(rowData, column) => {
                                        return <div>
                                            <Button disabled={true || !this.props.api.hasPolicy("tasks_edit")} onClick={() => {
                                                this.setState({ dialogVisible: true, currentAction: "task_edit", currentFields: rowData }, () => { console.log(this.state) })
                                            }} type="button" icon="pi pi-pencil" className="p-button-secondary"></Button>
                                            <Button disabled={!this.props.api.hasPolicy("tasks_edit")} onClick={() => {
                                                this.setState({
                                                    confirmVisible: true,
                                                    confirmMessage: "Aufgabe " + rowData.title + " archivieren?",
                                                    confirmFun: () => {
                                                        this.props.api.updateTask(rowData.id, { checked: 1 })
                                                            .then(r => {
                                                                if (r.status === 200) {
                                                                    this.showSuccess("Wurde erfolgreich archiviert.")
                                                                    this.props.refreshHours()
                                                                } else if (r.status == 420) {
                                                                    this.showError("Du darfst dieses Element nicht archivieren.")
                                                                } else {
                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                }
                                                            })
                                                            .catch(r => {
                                                                r.showError("Tätigkeit konnte nicht gelöscht werden.")
                                                            })
                                                    }
                                                })
                                            }} type="button" style={{ marginLeft: 10 }} icon="pi pi-check" className="p-button-success"></Button>
                                            <Button disabled={!this.props.api.hasPolicy("tasks_delete")} onClick={() => {
                                                this.setState({
                                                    confirmVisible: true,
                                                    confirmMessage: "Aufgabe " + rowData.title + " löschen?",
                                                    confirmFun: () => {
                                                        this.props.api.deleteTask(rowData.id)
                                                            .then(r => {
                                                                if (r.status === 200) {
                                                                    this.props.refreshHours()
                                                                    this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                } else if (r.status == 420) {
                                                                    this.showError("Du darfst dieses Element nicht löschen.")
                                                                } else {
                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                }
                                                            })
                                                            .catch(r => {
                                                                r.showError("Aufgabe konnte nicht gelöscht werden.")
                                                            })
                                                    }
                                                })
                                            }} type="button" style={{ marginLeft: 10 }} icon="pi pi-trash" className="p-button-danger"></Button>
                                        </div>
                                    }} />
                                </DataTable></div>
                                :
                                <p>Keine anstehenden Aufgaben gefunden.</p>
                        }
                    </div>
                    */}
                    <div className="card">

                        <h1>Tätigkeiten</h1>
                        <div className="header-actions">
                            <Button disabled={!this.props.api.hasPolicy("hours_create")} icon="pi pi-plus"
                                    onClick={() => {
                                        this.setState({
                                            currentAction: "insert",
                                            currentFields: {pid: this.props.pid, text: "", mins: 0, euros: 0, tid: 0},
                                            dialogVisible: true
                                        })
                                    }} label="Hinzufügen"/>
                        </div>
                        <div className="subtitle"><b>Hier ist die Arbeitszeitenerfassung.</b> Trage alle Tätigkeiten mit
                            Arbeitszeit und Tätigkeitsbeschreibung ein, eine Tätikeit kann auch einer Aufgabe zugeordnet
                            werden. Rechnungen werden ausschließlich aus dieser Tabelle erzeugt.
                        </div>

                        <Dialog style={{textAlign: "center"}} header="Fortfahren?" visible={this.state.confirmVisible}
                                modal={true} onHide={() => this.setState({confirmVisible: false})}
                                footer={<div>
                                    <Button onClick={() => {
                                        this.setState({confirmVisible: false})
                                    }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                    <Button onClick={() => {
                                        this.state.confirmFun();
                                        this.setState({confirmVisible: false})
                                    }} label="Fortfahren" className="p-button-primary" icon="pi pi-check"/>
                                </div>}>{this.state.confirmMessage}</Dialog>

                        <Dialog header={
                            this.state.currentAction === "insert" ? "Tätigkeit anlegen" :
                                this.state.currentAction === "task_create" ?
                                    "Aufgabe anlegen" : "Bearbeiten"
                        } visible={this.state.dialogVisible} modal={true}
                                onHide={() => this.setState({dialogVisible: false})}
                                footer={<div>
                                    <Button onClick={() => {
                                        this.setState({dialogVisible: false})
                                    }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                    <Button onClick={() => {
                                        if (this.state.currentAction === "insert") {
                                            // add the hour
                                            this.props.api.insertHour(this.props.pid, this.state.currentFields)
                                                .then((r) => {
                                                    this.setState({dialogVisible: false})
                                                    this.props.refreshHours()
                                                    this.showSuccess("Gespeichert.")
                                                })
                                                .catch((e) => {
                                                    console.log("e", e)
                                                    this.showError("Tätigkeit konnte nicht erstellt werden.")
                                                })
                                        } else if (this.state.currentAction === "task_edit") {
                                            // add the hour
                                            this.props.api.updateTask(this.props.pid, this.state.currentTaskFields)
                                                .then((r) => {
                                                    this.setState({dialogVisible: false})
                                                    this.showSuccess("Aufgabe Aktualisiert.")
                                                    this.props.refreshHours()
                                                })
                                                .catch((e) => {
                                                    console.log("e", e)
                                                    this.showError("Aufgabe konnte nicht aktualisiert werden.")
                                                })
                                        } else if (this.state.currentAction === "task_create") {
                                            // add the hour
                                            this.props.api.insertTask(this.props.pid, this.state.currentTaskFields)
                                                .then((r) => {
                                                    this.setState({dialogVisible: false})
                                                    this.showSuccess("Gespeichert.")
                                                    this.props.refreshHours()
                                                })
                                                .catch((e) => {
                                                    console.log("e", e)
                                                    this.showError("Aufgabe konnte nicht erstellt werden.")
                                                })
                                        } else {
                                            // update the hour
                                            this.props.api.updateHour(this.state.currentFields)
                                                .then((r) => {
                                                    this.setState({dialogVisible: false})
                                                    this.props.refreshHours()
                                                    this.showSuccess("Änderungen gespeichert.")
                                                })
                                                .catch((e) => {
                                                    this.showError("Speichern der Änderungen fehlgeschlagen.")
                                                })
                                        }
                                    }}
                                            label={this.state.currentAction !== "edit" && this.state.currentAction !== "task_edit" ? "Hinzufügen" : "Speichern"}
                                            icon="pi pi-check"/>
                                </div>}
                                className="form"
                        >
                            <div>
                                {
                                    this.state.currentAction === "task_create" ?
                                        <div>
                                            <InputText autoFocus={true} name="title"
                                                       value={this.state.currentTaskFields.title}
                                                       onChange={this.onTaskFieldChange.bind(this)}
                                                       placeholder={`Tätigkeit beschreiben`}/>
                                            <Dropdown name="assignee_uid" options={this.props.users.map(u => {
                                                return {label: u.fname, value: u.id}
                                            })} value={this.state.currentTaskFields.assignee_uid}
                                                      onChange={this.onTaskFieldChange.bind(this)}
                                                      style={{marginRight: 10}} placeholder="Wer?"/>
                                            <div className="p-inputgroup">
                                                <InputText name="mins" value={this.state.currentTaskFields.mins}
                                                           onChange={this.onTaskFieldChange.bind(this)}
                                                           placeholder="Minuten"/>
                                                <span className="p-inputgroup-addon">min</span>
                                            </div>
                                            <div className="p-inputgroup">
                                                <InputText name="euros" value={this.state.currentTaskFields.euros}
                                                           onChange={this.onTaskFieldChange.bind(this)}
                                                           placeholder="Betrag in Euro"/>
                                                <span className="p-inputgroup-addon">€</span>
                                            </div>
                                            <InputText name="deadline" value={this.state.currentTaskFields.deadline}
                                                       onChange={this.onTaskFieldChange.bind(this)}
                                                       placeholder={`Deadline`}/>
                                            <InputTextarea name="description" value={this.state.currentTaskFields.text}
                                                           onChange={this.onTaskFieldChange.bind(this)}
                                                           placeholder={`Tätigkeit genauer beschreiben`}/>
                                        </div>
                                        :
                                        <div>
                                            <InputTextarea autoFocus={true} name="text"
                                                           value={this.state.currentFields.text}
                                                           onChange={this.onFieldChange.bind(this)}
                                                           placeholder={`Tätigkeit beschreiben`}/>
                                            <div className="p-inputgroup">
                                                <InputText name="mins" value={this.state.currentFields.mins}
                                                           onChange={this.onFieldChange.bind(this)}
                                                           placeholder="Minuten"/>
                                                <span className="p-inputgroup-addon">min</span>
                                            </div>
                                            <div className="p-inputgroup">
                                                <InputText name="euros" value={this.state.currentFields.euros}
                                                           onChange={this.onFieldChange.bind(this)}
                                                           placeholder="Betrag in Euro"/>
                                                <span className="p-inputgroup-addon">€</span>
                                            </div>
                                            <Dropdown name="tid" style={{display: "none"}}
                                                      options={this.props.tasks.map((task) => {
                                                          return {label: task.title, value: task.id}
                                                      })} value={this.state.currentFields.tid}
                                                      onChange={this.onFieldChange.bind(this)}
                                                      placeholder="Zugeordnete Aufgabe"/>
                                        </div>
                                }
                            </div>
                        </Dialog>

                        {
                            this.props.hours.length > 0 ?
                                <DataTable value={this.props.hours}>
                                    <Column style={{width: "6em", textAlign: "end"}} field={"euros"} header={"Betrag"}
                                            body={(rowData, column) => {
                                                return rowData.tid === "-1" ?
                                                    <i className="pi pi-plus-circle pi-spin"/> :
                                                    <span className={"status-" + rowData.status}>
                                            {
                                                rowData.euros === "0" ? <span
                                                    className="muted">---</span> : parseFloat(rowData.euros).toFixed(2) + "€"
                                            }
                                        </span>

                                            }}/>
                                    <Column style={{width: "7em"}} field={"mins"} header={"Zeit"}
                                            body={(rowData, column) => {
                                                let mins = parseInt(rowData.mins)
                                                return rowData.tid === "-1" ?
                                                    <i className="pi pi-plus-circle pi-spin"/> : mins === 0 ?
                                                        <span className="muted">Festpreis</span> :
                                                        mins >= 60 ?
                                                            mins % 60 === 0 ? mins / 60 + "h" : Math.floor(mins / 60) + "h " + mins % 60 + "min" :
                                                            mins + "min"
                                            }}/>
                                    <Column style={{"white-space": "pre-line"}} field={"text"} header={"Tätigkeit"}
                                            body={(r, c) => {
                                                return <ReadMoreDiv text={r.text}/>
                                            }}/>
                                    <Column field={"tid"} header={"Aufgabe"} body={(rowData) => {
                                        for (let task of this.props.tasks) {
                                            if (rowData.tid === task.id)
                                                return <span>{task.title}
                                                    <div style={{opacity: .5}}>{task.description}</div></span>
                                        }
                                    }}/>
                                    <Column style={{width: "10em"}} field={"uid"} header={"Benutzer"}
                                            body={(rowData, column) => {
                                                for (let user of this.props.users) {
                                                    if (user.id === rowData.uid)
                                                        return user.fname
                                                }
                                                return "- - -"
                                            }}/>
                                    <Column field={"iss"} header={"Wann"} body={(r, c) => {

                                        var now = moment(new Date()); //todays date
                                        var end = moment(r.iss); // another date

                                        var duration = moment.duration(-now.diff(end));

                                        moment.locale('de')

                                        return duration.humanize(true)
                                    }}/>
                                    <Column style={{width: "11em", textAlign: "center"}} body={(rowData, column) => {
                                        return <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <Button
                                                disabled={false}
                                                type="button" icon="pi pi-refresh"
                                                onClick={() => {
                                                    let interval = window.prompt("In welchem Intervall soll die Tätigkeit wiederholt werden? (z.B. 1w für wöchentlich, 2d für alle 2 Tage, 1h für stündlich, 30m für alle 30 Minuten, 10s für alle 10 Sekunden, 1M für monatlich)")
                                                    if (interval) {
                                                        // interval in sekunden umrechnen
                                                        let intervalSec = 0
                                                        if (interval.endsWith("s")) {
                                                            intervalSec = parseInt(interval.substring(0, interval.length - 1))
                                                        } else if (interval.endsWith("m")) {
                                                            intervalSec = parseInt(interval.substring(0, interval.length - 1)) * 60
                                                        } else if (interval.endsWith("h")) {
                                                            intervalSec = parseInt(interval.substring(0, interval.length - 1)) * 60 * 60
                                                        } else if (interval.endsWith("d")) {
                                                            intervalSec = parseInt(interval.substring(0, interval.length - 1)) * 60 * 60 * 24
                                                        } else if (interval.endsWith("w")) {
                                                            intervalSec = parseInt(interval.substring(0, interval.length - 1)) * 60 * 60 * 24 * 7
                                                        } else if (interval.endsWith("M")) {
                                                            intervalSec = parseInt(interval.substring(0, interval.length - 1)) * 60 * 60 * 24 * 7 * 4
                                                        } else if (interval.endsWith("y")) {
                                                            intervalSec = parseInt(interval.substring(0, interval.length - 1)) * 60 * 60 * 24 * 365
                                                        } else {
                                                            intervalSec = parseInt(interval)
                                                        }
                                                        this.props.api.insertHourCron(rowData.id, {exec_interval:intervalSec, hid: rowData.id}).then(() => {
                                                            this.props.refreshHours()
                                                            //this.props.api.refreshProject()

                                                        })
                                                    }
                                                }}
                                                className="p-button-secondary"
                                            />
                                            <Button
                                                disabled={!this.props.api.hasPolicy("hours_edit") && !(rowData.uid === this.props.api.getUserfield("id") && rowData.ttl < 1500)}
                                                onClick={() => { // change if younger than 25 minutes
                                                    this.setState({
                                                        dialogVisible: true,
                                                        currentAction: "edit",
                                                        currentFields: rowData
                                                    }, () => {
                                                        console.log(this.state)
                                                    })
                                                }} type="button" icon="pi pi-pencil"
                                                className="p-button-secondary"></Button>
                                            <Button disabled={!this.props.api.hasPolicy("hours_delete")}
                                                    onClick={() => {
                                                        this.setState({
                                                            confirmVisible: true,
                                                            confirmMessage: "Tätigkeit " + rowData.text + " löschen?",
                                                            confirmFun: () => {
                                                                this.props.api.deleteHour(rowData.id)
                                                                    .then(r => {
                                                                        if (r.status === 200) {
                                                                            this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                            this.props.refreshHours()
                                                                        } else if (r.status == 420) {
                                                                            this.showError("Du darfst dieses Element nicht löschen.")
                                                                        } else {
                                                                            this.showError("Es ist ein Fehler aufgetreten.")
                                                                        }
                                                                    })
                                                                    .catch(r => this.showError("Konnte nicht gelöscht werden."))
                                                            }
                                                        })
                                                    }} type="button" icon="pi pi-trash"
                                                    className="p-button-danger"></Button>
                                        </div>
                                    }}/>
                                </DataTable>
                                :
                                <p>Keine Einträge gefunden.</p>
                        }

                    </div>

                    <div className="card">
                        <h1>Wiederhole Tätigkeiten</h1>
                        <div className={"subtitle"}>Diese Tätigkeiten werden alle <b>&lt;Intervall&gt;</b> Sekunden
                            kopiert. Ideal für Hosting Verträge. CarePlus wird bereits über den CarePlus Tab
                            abgerechnet.
                        </div>
                        {
                            this.props.hourcrons.length > 0 ? <DataTable value={this.props.hourcrons}>
                                    <Column style={{width: "6em", textAlign: "end"}} field={"euros"} header={"Betrag"}/>
                                    <Column style={{width: "6em", textAlign: "end"}} field={"exec_interval"}
                                            header={"Intervall"}/>
                                    <Column style={{"white-space": "pre-line"}} field={"text"} header={"Tätigkeit"}/>
                                    <Column field={"last_execution"} header={"Letzte Ausführung"}/>
                                    <Column field={"ttl"} header={"Letzte Ausführung"} body={(r, c) => {
                                        return moment(r.last_execution).fromNow()
                                    }}/>
                                    <Column field={"ttl"} header={"Nächste Ausführung"} body={(r, c) => {
                                        return moment(r.last_execution).add(r.exec_interval, 'seconds').toISOString().replace('T', ' ').replace('Z', '').replace('.000', '')
                                    }}/>
                                    <Column field={"ttl"} header={"Nächste Ausführung"} body={(r, c) => {
                                        return moment(r.last_execution).add(r.exec_interval, 'seconds').fromNow()
                                    }}/>
                                    <Column style={{width: "8em", textAlign: "center"}} body={(rowData, column) =>
                                        <Button disabled={!this.props.api.hasPolicy("hours_delete")}
                                                onClick={() => {
                                                    if (window.confirm("Wirklich löschen?")) {
                                                        this.props.api.deleteHourCron(rowData.id)
                                                            .then(r => {
                                                                if (r.status === 200) {
                                                                    this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                    this.props.refreshHours()
                                                                } else if (r.status == 420) {
                                                                    this.showError("Du darfst dieses Element nicht löschen.")
                                                                } else {
                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                }
                                                            })
                                                            .catch(r => this.showError("Konnte nicht gelöscht werden."))
                                                    }
                                                }}
                                                type="button" style={{marginLeft: 10}} icon="pi pi-trash"
                                                className="p-button-danger"></Button>
                                    }/>
                                </DataTable> :
                                <p>Keine Einträge gefunden.</p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
