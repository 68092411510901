import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { Route } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import { UsersPage } from './components/UsersPage';
import { ProjectsPage } from './components/ProjectsPage';
import { CustomersPage } from './components/CustomersPage';
import { QuotesPage } from './components/QuotesPage';
import { HoursPage } from './components/HoursPage';
import { ConversationsPage } from './components/ConversationsPage';
import { DocsPage } from './components/DocsPage';
import { CarePage } from './components/CarePage';
import { InvoicesPage } from './components/InvoicesPage';
import { ExpensesPage } from './components/ExpensesPage';
import { BruttoPage } from './components/BruttoPage';
import { LoginPage } from './components/LoginPage';
import { SettingsPage } from './components/SettingsPage';
import { ReviewPage } from './components/ReviewPage';
import { Link,Redirect,Switch } from 'react-router-dom'
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';

import "./layout/login.css"

import Api from './Api'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const DEVELOP_OFFLINE = false

class App extends Component {

    onProjectChoose(pid) {
        pid = ""+(pid)
        cookies.set('pid', pid, { path: '/' , maxAge: 3600*24*30 });
        if(this.state.api.getUserfield("recent_pid") !== ""+pid)
            this.state.api.updateUser({id:this.state.api.getUserfield("id"),recent_pid:pid})
        this.setState({ pid },()=>{
            this.setCToken()
            this.refreshProject()

            if(window.location.href.includes("/projects")) {
                window.location.href='/#/conversations'
            }
        })
   }


    refreshProject() {
        // also refreshes 
        this.state.api.getProject(this.state.pid)
            .then(d => d.json())
            .then(d => {
                console.log("refreshProject",d)
                this.setState({ hours: d.hours, hourcrons: d.hourcrons ?? [], tasks: d.tasks, wages: d.wages,
                    brutto : d.brutto,
                    tickets : d.tickets,
                    invoices: d.invoices,
                    invoicecrons: d.invoicecrons,
                    subscriptions:d.subscriptions,
                    subscription_plans:d.subscription_plans,
                    quotes: d.quotes,
                    surveys: d.surveys,
                    hoursQueue: d.hoursQueue
                },()=>{
                    this.storeToLocalStorage()
                })
            })
            .catch(e => console.error("Konnte Stunden/Tasks/Wages nicht aktualisieren."))
       
            //this.refreshTrello()
    }

    refreshTrello() {
        return;
 
        let thatApi = this.state.api
        thatApi.getTrelloBoards().then(res=>res.json())
            .then(d=>{
                let slug = ""
                for(let p of this.state.projects) {
                    if(p.id === this.state.pid)
                        slug = p.slug
                }
                let gotBoardInfo
                for(let board of d) {
                    if(board.name.toUpperCase() === slug) {
                        gotBoardInfo = true
                        // get board infos
                        thatApi.getTrelloLists(board.id).then(res=>res.json())
                            .then(lists=>{
                                this.setState({trello:{
                                    ...this.state.trello,
                                    lists 
                                }})
                            })
                        thatApi.getTrelloCards(board.id).then(res=>res.json())
                            .then(cards=>{
                                this.setState({trello:{
                                    ...this.state.trello,
                                    cards 
                                }})
                            })
                    }
                }
                if(!gotBoardInfo)
                    this.setState({trello:{cards:[],lists:[]}})
            })

    }

    refreshCustomers() {
        this.state.api.getCustomers()
            .then(d => d.json())
            .then(d => this.setState({ customers: d.data }))
            .catch(e => console.log(e))
    }


    refreshProjects() {
        this.state.api.getProjects()
            .then(d => d.json())
            .then(d => this.setState({ projects: d.data }))//remove isCache: false, 
            .catch(e => console.log(e))
    }

    refreshQuotes() {
        // TODO
        this.props.api.getQuotes(this.state.pid).then(d => d.json())
            .then(d => this.setState({ quotes: d.data }))
            .catch(e => console.log(e))
    }

    setLoaderEnabled(loadingVisible) {
        this.setState({ loadingVisible : ( loadingVisible ? this.state.loadingVisible + 1 : this.state.loadingVisible - 1)  })
    }

    createProjectWithCustomer(cid) {
        this.setState({createProjectWithCustomer_cid:cid, redirect: "/projects" });
    }

    refreshInitial() {
        this.state.api.getInitial()
            .then(d => d.json())
            .then(d => {
                if(d.error !== undefined && d.error === "ip_changed")
                    this.forceLogout(0)
                this.setState({
                    isCache: false,
                    projects: d.projects,
                    customers: d.customers,
                    users: d.users.map(user => {
                        user.pass = ""
                        return user
                    })
                })
            })
            .catch(e => console.log(e))
    }



    refreshUsers() {
        this.state.api.getUsers()
            .then(d => d.json())
            .then(d => {
                this.setState({
                    users: d.data.map(user => {
                        user.pass = ""
                        return user
                    })
                })
            })
        .catch(e => {
            console.error("ERO",e)
        })
    }

    setAccessToken(accessToken) {
        var expires = new Date(new Date().getTime() + 2 * 24 * 3600 * 1000); // 2 Tage gültig
        cookies.set('accessToken', accessToken, { path: '/'  , maxAge: 3600*24*30 });//, expires
        window.location.reload()
    }

    forceLogout(trigger) {
        if(trigger === 0)
            alert("logout due to ip changed")
        this.clearAccessToken()
    }
    clearAccessToken() {
        cookies.remove('accessToken');
        cookies.remove('pid');
        window.location.reload()
    }

    storeToLocalStorage() {
        localStorage.setItem("state_cached",JSON.stringify(this.state))
    }
    
    loadFromLocalStorage() {
        let stateJSON = localStorage.getItem("state_cached")
        if(stateJSON === null)
            return
        let state = JSON.parse(localStorage.getItem("state_cached"))

        if(state === undefined || state.invoices === undefined) 
            return// some invalid cache, will be overridden anyways and ignored till then

        console.log("Brutto == ",state.brutto)

        this.state.api.load(state.api)
        this.setState({
            pid:state.pid,
            isCache : true,
            projects:state.projects,
            customers:state.customers,
            users:state.users,
            wages:state.wages,
            subscriptions:state.subscriptions,
            subscription_plans:state.subscription_plans,
            hours:state.hours,
            hourcrons:state.hourcrons ?? [],
            tickets:state.tickets,
            hoursQueue:state.hoursQueue,
            tasks:state.tasks,
            quotes:state.quotes,
            surveys:state.surveys,
            invoices:state.invoices,
            brutto:state.brutto,
            expenses:state.expenses,
            trello: state.trello,
         
        })

    }

   
    componentDidMount() {
    
        this.loadFromLocalStorage()
        //setTimeout(this.storeToLocalStorage.bind(this),5000)

    }

    constructor(props) {
        super(props);

        this.state = {
            api: undefined,
            pid: cookies.get('pid'), // undefined
            ctoken: 'token_not_set',
            layoutMode: 'static',
            layoutColorMode: 'new',
            staticMenuInactive: false,
            loadingVisible: 1,
            isCache : true,
            overlayMenuActive: false,
            mobileMenuActive: false,
            projects: [],
            customers: [],
            users: [],

            createProjectWithCustomer_cid:undefined,
            redirect: undefined,


            // moved up states
            hours: [],
            hourcrons: [],
            tickets: [],
            tasks: [],
            wages: [],
            subscriptions: [],
            subscription_plans: [],
            invoices: [],
            quotes: [],
            surveys: [],
            brutto: [],
            hoursQueue: [],
            expenses: [], // for later

            trello: {
                lists: [],
                cards: []
            }


        };

        let api = new Api({ accessToken: cookies.get("accessToken"), setAccessToken: this.setAccessToken }, (thatApi) => {
/*
            if(thatApi === undefined && cookies.get('pid') !== undefined) {
                this.clearAccessToken()
            }
            */

            this.setState({ api : thatApi }, () => {

                /*
                this.refreshProjects()
                this.refreshCustomers()
                this.refreshUsers()
                */

                this.refreshInitial()

                if(this.state.pid === undefined) {
                    this.onProjectChoose(thatApi.getUserfield("recent_pid"))
                } else  {
                    let recent_pid=parseInt((thatApi.getUserfield("recent_pid")))
                    if(recent_pid<=0)
                        this.onProjectChoose(this.state.pid)
                    else
                        this.onProjectChoose(thatApi.getUserfield("recent_pid"))
                }

            })
        },()=>{
            if(cookies.get("accessToken") !== undefined)
                this.clearAccessToken()
        }, this.setLoaderEnabled.bind(this))

        this.state.api = api;

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.createMenu();


        this.debugFunction()
    }

    debugFunction() {

        // after 1s output hoursQueue
        setTimeout(() => {
            console.log("hoursQueue",this.state)
        }, 1000)

    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if (event === null || !event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
        this.menu = [

            // regelmäßige aufnahme von neuen kosten
            { label: 'Zeiterfassung', img: 'hours.svg', icon: 'pi pi-fw pi-calendar', to: '/hours', policy: "hours_list" },
            { label: 'Kundenkontakt', img:'conversations.svg', icon: 'pi pi-fw pi-list', to: '/conversations', policy: "hours_list" },

            // kunden service
            { label: 'Care+', project_type:'website', img: 'careplus.png', icon: 'pi pi-fw pi-globe', to: '/care', policy: "projects_edit" },
            { label: 'Review', project_type:'imagefilm', icon: 'pi pi-fw pi-video', to: '/review', policy: "reviews_edit" },

            // abrechnung
            { label: 'Angebote', icon: 'pi pi-fw pi-inbox', to: '/quotes', policy: "quotes_list" },
            { label: 'Rechnungen', icon: 'pi pi-fw pi-envelope', to: '/invoices', policy: "invoices_list" },
            { label: 'Brutto', icon: 'pi pi-fw pi-dollar', to: '/brutto', policy: "brutto_list" },
            { label: 'Ausgaben', icon: 'pi pi-fw pi-shopping-cart', to: '/expenses', policy: "expenses_list" },

            // verwaltung
            { label: 'Projekte', icon: 'pi pi-fw pi-sitemap', to: '/projects', policy: "projects_list" },
            { label: 'Kund:innen', icon: 'pi pi-fw pi-users',  to: '/customers', policy: "customers_edit" },

            // adminstration
            { label: 'Benutzer', icon: 'pi pi-fw pi-user',  to: '/users', policy: "users_edit" },
            { label: 'Docs', icon: 'pi pi-fw pi-folder-open', to: '/docs', policy: "policies_edit" },

            // lagerverwaltug
            { label: 'S2', icon: 'pi pi-fw pi-box', to: '/s2/#', policy: "projects_edit" },
        ];
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    getProject() {
        let projects = this.state.projects.filter(p=>p.id == this.state.pid)
        if(projects != undefined && projects.length)
            return projects[0]
        else
            return {type:null,error:"project not found"}
    }

    getCustomer() {
        let cid = this.getProject().cid
        if(cid == undefined)
            return null
        let customer = this.state.customers.filter(c => c.id === cid)
        if(customer.length > 0)
            return customer[0]
        else
            return null
    }
    setCToken() {
        let customer = this.getCustomer()
        if(customer != null)
            this.setState({ctoken: customer.token})
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {

        //if(this.state.loadingVisible < 0) this.setState({loadingVisible:0})

        if(this.state.redirect !== undefined) {
            this.setState({redirect:undefined})
            return <Redirect to={{
                pathname:this.state.redirect,
                state: {createProjectWithCustomer_cid: this.state.createProjectWithCustomer_cid}
            }}/>
        }

        const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg' : 'assets/layout/images/logo.svg';

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });


        if (!DEVELOP_OFFLINE &&cookies.get("accessToken") === undefined)
            return <LoginPage api={this.state.api} />
        else
            return (
                <div className={wrapperClass} onClick={this.onWrapperClick}>

                    <AppTopbar
                        isCache={this.state.isCache}
                        loadingVisible={this.state.loadingVisible > 0}
                        pid={this.state.pid}
                        projects={this.state.projects}
                        onProjectChoose={this.onProjectChoose.bind(this)}
                        onToggleMenu={this.onToggleMenu} />

                    <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                        <div style={{ display: "none" }} className="layout-logo">
                            <img alt="Logo" src={logo} />
                        </div>
                        <AppProfile clearAccessToken={this.clearAccessToken} onMenuItemClick={this.onMenuItemClick} api={this.state.api} />
                        <AppMenu api={this.state.api} project={this.getProject()} model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                    </div>

                    {this.state.pid === undefined && !window.location.href.includes("/projects") ?
                        <div className="layout-main"
                        >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card"
                                        style={{ zIndex: 2000, width: 500, position: "fixed", left: "50%", marginLeft: -250, boxShadow: "0 0 0 2000px #000a" }}
                                    >
                                        <h1>Bitte zuerst ein Projekt wählen:</h1>
                                        <Dropdown
                                            style={{ width: "100%" }}
                                            value={this.state.pid}
                                            options={this.state.projects.map(project => { return { label: project.slug + " " + project.title, value: project.id } })}
                                            onChange={(e) => { this.onProjectChoose(e.value) }}
                                            placeholder="Projekt auswählen" />
                                        {this.state.api.hasPolicy("projects_create") && <div style={{ marginTop: 5 }}>Sie können alternativ auch ein <Link to="/projects"> Projekt erstellen</Link></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="layout-main">
                            <Switch>
                                <Route path="/hours" render={(props) => <HoursPage {...props} trello={this.state.trello} refreshHours={this.refreshProject.bind(this)} wages={this.state.wages} tasks={this.state.tasks} hours={this.state.hours} hourcrons={this.state.hourcrons} users={this.state.users} pid={this.state.pid} api={this.state.api} />} />
                                <Route path="/conversations" render={(props) => <ConversationsPage {...props} surveys={this.state.surveys} refreshHours={this.refreshProject.bind(this)} tickets={this.state.tickets} wages={this.state.wages} tasks={this.state.tasks} hours={this.state.hours} users={this.state.users} pid={this.state.pid} api={this.state.api} ctoken={this.state.ctoken} customer={this.getCustomer()}  />}/>
                                <Route path="/expenses" render={(props) => <ExpensesPage {...props} expenses={this.state.expenses} users={this.state.users} api={this.state.api} />} />
                                <Route path="/quotes" render={(props) => <QuotesPage {...props} projects={this.state.projects} refreshProject={this.refreshProject.bind(this)} quotes={this.state.quotes} pid={this.state.pid} project={this.getProject()} api={this.state.api} />} />
                                <Route path="/invoices" render={(props) => <InvoicesPage {...props} refreshProject={this.refreshProject.bind(this)} invoicecrons={this.state.invoicecrons} invoices={this.state.invoices} pid={this.state.pid} api={this.state.api} />} />
                                <Route path="/brutto" render={(props) => <BruttoPage {...props} projects={this.state.projects} hoursQueue={this.state.hoursQueue} refreshProject={this.refreshProject.bind(this)} invoices={this.state.brutto} pid={this.state.pid} api={this.state.api} onProjectChoose={this.onProjectChoose.bind(this)} />} />
                                <Route path="/users" render={(props) => <UsersPage {...props} refreshUsers={this.refreshUsers.bind(this)} users={this.state.users} api={this.state.api} />} />
                                <Route path="/projects" render={(props) => <ProjectsPage {...props} pid={this.state.pid} onProjectChoose={this.onProjectChoose.bind(this)} users={this.state.users} refreshProjects={this.refreshProjects.bind(this)} customers={this.state.customers} projects={this.state.projects} api={this.state.api} />} />
                                <Route path="/care" render={(props) => <CarePage {...props} subscriptions={this.state.subscriptions} subscription_plans={this.state.subscription_plans} pid={this.state.pid} onProjectChoose={this.onProjectChoose.bind(this)} refreshProject={this.refreshProject.bind(this)} refreshProjects={this.refreshProjects.bind(this)} projects={this.state.projects} api={this.state.api} />} />
                                <Route path="/review" render={(props) => <ReviewPage {...props} refreshProject={this.refreshProject.bind(this)} pid={this.state.pid} api={this.state.api} />} />
                                <Route path="/customers" render={(props) => <CustomersPage {...props} createProjectWithCustomer={this.createProjectWithCustomer.bind(this)}  refreshCustomers={this.refreshCustomers.bind(this)} users={this.state.users}  customers={this.state.customers} api={this.state.api} />} />
                                <Route path="/docs" render={(props) => <DocsPage />} />
                                <Route path="/settings" render={(props) => <SettingsPage api={this.state.api} />} />
                            </Switch>
                        </div>
                    }

                    {/*  <AppFooter /> */}

                    <div className="layout-mask"></div>
                </div >
            );
    }
}

export default App;
