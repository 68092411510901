import React, { Component } from 'react';
import classNames from 'classnames';
import {Redirect} from 'react-router-dom'
import config from "./config";

export class AppProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        this.setState({ expanded: !this.state.expanded });
        event.preventDefault();
    }

    render() {
        return (
            <div className="layout-profile">
                <div>
                    <img style={{width:"56px",height:"56px"}} src="assets/layout/images/profile.png" alt="" />
                </div>
                <button className="p-link layout-profile-link" onClick={this.onClick}>
                    <span className="username">{this.props.api.getUserfield("fname")}</span>
                    <i className="pi pi-caret-down" style={{opacity:.3}} />
                </button>
                <ul className={classNames({ 'layout-profile-expanded': this.state.expanded })}>
                    <li><button onClick={() => { this.setState({expanded:false});this.props.onMenuItemClick(null); window.location.href="/#/settings"}} className="p-link"><i className="pi pi-fw pi-cog" /><span>Einstellungen</span></button></li>
                    <li><button onClick={() => {window.prompt("Bitte einloggen mit Benutzer 'alan' und Passwort:","4LarSMZUrC5e9M3W");window.location.href=config.doc_url}} className="p-link"><i className="pi pi-fw pi-key" /><span>API Dokumentation</span></button></li>
                    <li><button onClick={() => {this.props.api.insertApikey().then(d=>d.json()).then(d=>alert("Erzeugter API Schlüssel: "+d.data))}} className="p-link"><i className="pi pi-fw pi-key" /><span>API Key erstellen</span></button></li>
                    <li><button onClick={() => { this.props.clearAccessToken() }} className="p-link"><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>
                </ul>
            </div>
        );
    }
}
