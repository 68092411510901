import React, {useRef, Component} from 'react';
import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import {Dropdown} from 'primereact/dropdown';
import {ProgressBar} from 'primereact/progressbar';

import {InputTextarea} from 'primereact/inputtextarea';

export class AppTopbar extends Component {

    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "/") {
            console.log(this.projectSelect)
            this.projectSelect.show()
            //Do whatever when esc is pressed
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    /*
                */

    render() {
        var projects = this.props.projects

        return (
            <div className="layout-topbar clearfix">

                <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                    <span className="pi pi-bars"/>
                </button>

                <Dropdown
                    ref={input => this.projectSelect = input}
                    className={!this.props.loadingVisible ? "projectbar" : "projectbar loading"}
                    value={this.props.pid}
                    itemTemplate={(option) => {
                        let r
                        for (let i = 0; i < projects.length; i++) {
                            let p = projects[i]
                            if (parseInt(p.id) == parseInt(option.value))
                                r = p
                        }
                        return <>
                            <div className={"careicon careicon-" + r.care}>
                                <i className={"pi pi-" + (r.type === "website" ? "globe" : r.type === "imagefilm" ? "video" : "folder")}></i>
                            </div>


                            &emsp;
                            {option.label.split("||")[0]}

                            <img
                                src={r.company === 'Ziegenhagel Media' ? "https://assets.ziegenhagel.com/img/icon.png" : "https://faktorxmensch.com/wp-content/uploads/2023/01/cropped-logo_quibic.png"}
                                style={{width: 20, height: 20,float:"right", marginRight: 5}}/>
                        </>
                    }}
                    filter={true}
                    autoFocusFilterInput={true}
                    filterPlaceholder={"Nach Projekten suchen..."}
                    autoFocus={true}
                    tabIndex={0}
                    options={this.props.projects.filter(project => project.status !== "trash" || this.props.pid === project.id).map(project => {
                        return {
                            label: project.slug + " #" + project.id + " " + project.title + " (" + project.fname + " " + project.lname + ") || " + project.company,
                            value: project.id
                        }
                    })}
                    onChange={(e) => {
                        this.props.onProjectChoose(e.value)
                    }}
                    placeholder="Projekt auswählen"/>

                <span className="wifi-icon" style={{
                    position: "absolute",
                    color: "#fffe",
                    background: (this.props.isCache ? "#f00" : "#fff3"),
                    marginTop: -10,
                    textAlign: "center",
                    paddingTop: 4,
                    right: 0
                }}>
                    <i style={{fontSize: "3em"}} className="pi pi-wifi"/>
                </span>

                {
                    this.props.loadingVisible &&
                    <i className="pi pi-spin pi-spinner"
                       style={{position: "absolute", marginTop: -3.5, marginLeft: .7, fontSize: "2.5em"}}></i>
                }

            </div>
        );
    }
}
