import React, { Component } from 'react';
import { DataTable, Column } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Growl } from 'primereact/growl';
import { customerSex, customerStatus } from '../Api'
import { Dropdown } from 'primereact/dropdown';

export class CustomersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false,
            impressDump: "",
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => { console.log("not implemented") },
            searchPattern:"",
            isSearch:true,
            currentFields: {status:"active", company:"",url:"",title: "",
                sex: "", address1: "", address2: "", email: "", phone: "", 
                iss: "", fname: "", lname: "", uid: 0 },
            currentAction: "insert",
        };
    }

    onFieldChange(e) {
        if(e.target.name === "searchPattern") {
            this.setState({searchPattern: e.target.value}) 
        } else {
            let currentFields = this.state.currentFields
            currentFields[e.target.name] = e.target.value
            this.setState({ currentFields })
        }
    }

    impressDump(e) {
        let regex_email = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/ig
        let regex_phone = /[0-9\+]+[0-9 \-]+[0-9 \-]+[0-9 \-]+[0-9 \-]+[0-9 \-]+[0-9]/g
        let regex_address = /.+ .*[0-9]+(\n|, )[0-9]+ .+/g
        let regex_url = /@?[^\n/@]((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})\.?[a-zA-Z]*/g
        let regex_name = /[A-ZÜÖÄ][\wöüäÜÖÄß]+(\.)? [a-zA-ZöäüÖÄÜß\. ]*[A-ZÜÖÄ][\wöüäÜÖÄß]+/g // https://api.genderize.io/?name[]=peter&name[]=lois&name[]=stevie

        let text=e.target.value
        this.setState({impressDump:text})

        let lead = {
             email : text.match(regex_email) || [],
             phone : text.match(regex_phone) || [],
             address : text.match(regex_address) || [],
             url : text.match(regex_url) || [],
             name : text.match(regex_name) || []
        }

        // do cleanup
        lead.phone = lead.phone.map(s=>s.replace("+49","0").replace(/(-| )/g,""))
        let email_urls = lead.url.filter(s=>s.includes("@"))
        if(email_urls.length < lead.url.length)
            lead.url=lead.url.filter(s=>!s.includes("@"))
        lead.url = lead.url.map(s=>s.replace("@",""))
        lead.name = lead.name.filter(s=>!s.match(/ [A-ZÄÄÜ][a-zöäüß]*[A-ZÜÖÄ]/))
        lead.address = lead.address.map(s=>s.replace("\n",", ").split(", "))

        function onlyUnique(value, index, self) { return self.indexOf(value) === index; }

        lead.name = lead.name.filter(onlyUnique)
        lead.url = lead.url.filter(onlyUnique)
        lead.phone = lead.phone.filter(onlyUnique)
        lead.email = lead.email.filter(onlyUnique)

        if(!(lead.name.length>0 && lead.name[0].includes(" ")))
            lead.name=" "
        if(!(lead.address.length>0 && lead.address[0].length>0))
            lead.address=", "


        let fname=lead.name[0].split(" ")[0] || ""
        let sex="m"
        if(fname.length>0)
        fetch("https://api.genderize.io?name="+fname).then(d=>d.json())
        .then(o=>{
            if(o.probability > 0.7)
                this.setState({currentFields:{...this.state.currentFields, sex: o.gender == "female" ? "w" : "m" }})
        })

        this.setState({currentFields:{...this.state.currentFields,
            sex,
            fname:lead.name[0].split(" ")[0] || "",
            lname:lead.name[0].split(" ")[1] || "",
            status:"lead",
            url:lead.url[0] || "",
            email:lead.email[0] || "",
            phone:lead.phone[0] || "",
            address1:lead.address[0][0],
            address2:lead.address[0][1],
        }})

        console.log("impress dump -> lead",lead)
    }

    showSuccess(detail) {
        this.growl.show({ closable: true, severity: 'success', summary: 'Erfolgreich druchgeführt!', detail });
    }

    showError(detail) {
        this.growl.show({ closable: true, severity: 'error', summary: 'Fehler!', detail });
    }

    addCustomerBtn() {
      if (this.state.currentAction === "insert") {
        // add the customer
        this.props.api.insertCustomer(this.state.currentFields)
            .then((r) => {
                return r.json()
            })
            .then((r) => {
                setTimeout(()=>{this.props.createProjectWithCustomer(r.insert_id)},800);
                this.setState({ dialogVisible: false })
                this.showSuccess("Gespeichert.")
                this.props.refreshCustomers()
            })
            .catch((e) => {
                this.showError("Konnte nicht erstellt werden.")
                })
        } else {
            // update the customer
            this.props.api.updateCustomer(this.state.currentFields)
                .then((r) => {
                    this.setState({ dialogVisible: false })
                    this.showSuccess("Änderungen gespeichert.")
                    this.props.refreshCustomers()
                })
                .catch((e) => {
                    this.showError("Speichern der Änderungen fehlgeschlagen.")
                }
                )}

    }


    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                        <h1>Kund:innen</h1>
                        <div className="header-actions">
                            <Button icon="pi pi-trash" onClick={() => {this.props.api.deleteLeads().then(e=>this.props.refreshCustomers())}} label="" />
                            <Button icon="pi pi-search" className={this.state.isSearch ? "" : "notactive"} onClick={() => { this.setState({ searchPattern: "", isSearch : !this.state.isSearch }) }} label="" />
                            <Button disabled={!this.props.api.hasPolicy("customers_create")} icon="pi pi-plus" onClick={() => { this.setState({ currentAction: "insert", currentFields: {company:"",url:"", title: "", sex: "", address1: "", address2: "", email: "", phone: "", iss: "", fname: "", lname: "", uid: 0 }, dialogVisible: true }) }} label="Hinzufügen" />
                        </div>

                        <Dialog style={{ textAlign: "center" }} header="Fortfahren?" visible={this.state.confirmVisible} modal={true} onHide={() => this.setState({ confirmVisible: false })}
                            footer={<div>
                                <Button onClick={() => { this.setState({ confirmVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                                <Button onClick={() => { this.state.confirmFun(); this.setState({ confirmVisible: false }) }} label="Fortfahren" className="p-button-primary" icon="pi pi-check" />
                            </div>} >{this.state.confirmMessage}</Dialog>

                        <Dialog header={
                            this.state.currentAction === "insert" ?
                                "Hinzufügen" : "Bearbeiten"
                        } visible={this.state.dialogVisible} modal={true} onHide={() => this.setState({ dialogVisible: false })}
                            footer={<div>
                                <Button onClick={() => { this.setState({ dialogVisible: false }) }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times" />
                                <Button onClick={() => { this.addCustomerBtn() }} label={this.state.currentAction === "insert" ? "Hinzufügen" : "Speichern"} icon="pi pi-check" />
                                { this.state.currentAction === "insert" && <Button className="fullblock" onClick={() => { this.addCustomerBtn() }} label="Hinzufügen & Projekt anlegen" icon="pi pi-plus" /> }
                            </div>}
                            className="form"
                        >
                            <div>
                                <InputTextarea name="impressDump" value={this.state.impressDump} onChange={this.impressDump.bind(this)} placeholder="Impressums Text Dump" />
                                <div class="d-flex">
                                    <Dropdown name="status" value={this.state.currentFields.status} options={customerStatus} onChange={this.onFieldChange.bind(this)} placeholder="Status" />
                                    <InputText name="company" value={this.state.currentFields.company} onChange={this.onFieldChange.bind(this)} placeholder="Firma" />
                                </div>
                                <div class="d-flex">
                                    <Dropdown name="sex" value={this.state.currentFields.sex} options={customerSex} onChange={this.onFieldChange.bind(this)} placeholder="Anrede" />
                                    <InputText name="title" value={this.state.currentFields.title} onChange={this.onFieldChange.bind(this)} placeholder={`Titel (z.b: "Dr. ","Dr. Prof.") [mit Leerzeichen endend]`} />
                                </div>
                                <div class="d-flex">
                                    <InputText name="fname" value={this.state.currentFields.fname} onChange={this.onFieldChange.bind(this)} placeholder="Vorname" />
                                    <InputText name="lname" value={this.state.currentFields.lname} onChange={this.onFieldChange.bind(this)} placeholder="Nachname" />
                                </div>
                                <div class="d-flex">
                                    <InputText name="address1" value={this.state.currentFields.address1} onChange={this.onFieldChange.bind(this)} placeholder="Strasse & Hausnummer" />
                                    <InputText name="address2" value={this.state.currentFields.address2} onChange={this.onFieldChange.bind(this)} placeholder="PLZ & Ort" />
                                </div>
                                <div class="d-flex">
                                    <InputText name="email" value={this.state.currentFields.email} onChange={this.onFieldChange.bind(this)} placeholder="E-mail" />
                                    <InputText name="phone" value={this.state.currentFields.phone} onChange={this.onFieldChange.bind(this)} placeholder="Telefonnummer" />
                                    <InputText name="url" value={this.state.currentFields.url} onChange={this.onFieldChange.bind(this)} placeholder="Webseiten Adresse" />
                                </div>
                            </div>
                        </Dialog>

                        { this.props.customers.length > 0 && this.state.isSearch && <InputText name="searchPattern" onKeyUp={(e)=>{if(e.keyCode === 27) this.setState({searchPattern:""}); if(e.keyCode === 13 || e.keyCode === 27) this.setState({isSearch:false})}} value={this.state.searchPattern} onChange={this.onFieldChange.bind(this)} placeholder="Suchen nach ..." autoFocus={true} /> }

                        {
                            this.props.customers.length > 0 ?
                                <DataTable value={this.props.customers.slice().reverse().filter((o)=>{
                                    return o.status !== "archive" && (this.state.searchPattern === "" || o.fname.toLowerCase().includes(this.state.searchPattern.toLowerCase()) || o.lname.toLowerCase().includes(this.state.searchPattern.toLowerCase()))
                                })}>
                                    <Column sortable field={"id"} header={"ID"} style={{width:70}} />
                                    <Column sortable field={"lname"} header={"Name"} body={(rowData, column)=>{return rowData.fname+" "+rowData.lname;}} />
                                    <Column sortable field={"company"} header={"Firma"} />
                                    <Column field={"email"} header={"E-mail"} body={(row,col)=>{
                                        if(row.email !== "")
                                            return <a href={"mailto:"+row.email} title={row.email}><i class="pi pi-envelope"></i> E-Mail</a>
                                    }} />
                                    <Column field={"phone"} header={"Telefon"}
                                    body={(row,col)=>{
                                        if(row.phone !== "")
                                            return <a href={"tel:"+row.phone} title={row.phone}><i class="pi pi-external-link"></i> Anruf</a>
                                    }} />
                                    <Column field={"url"} header={"Web"} body={(row,col)=>{
                                        if(row.url !== "")
                                            return <a target="_blank" href={row.url} title={row.url}><i class="pi pi-external-link"></i> Link</a>
                                    }} />
                                    <Column sortable field={"uid"} header={"Ansprechperson"} body={(row,col)=>{
                                        return this.props.users.map(user => {
                                            if(user.id == row.uid)
                                                return user.fname+" "+user.lname[0]+".";
                                        }) 
                                    }} />
                                    <Column style={{ width: "8em", textAlign: "center" }} body={(rowData, column) => {
                                        return <div>
                                            <Button disabled={!this.props.api.hasPolicy("customers_edit")} onClick={() => {
                                                this.setState({ dialogVisible: true, currentAction: "edit", currentFields: rowData }, () => { console.log(this.state) })
                                            }} type="button" icon="pi pi-pencil" className="p-button-secondary"></Button>
                                            <Button disabled={!this.props.api.hasPolicy("customers_delete") && rowData.status!="lead"} onClick={() => {
                                                this.setState({
                                                    confirmVisible: true,
                                                    confirmMessage: "Kunde*in " + rowData.fname + " " + rowData.lname + " löschen?",
                                                    confirmFun: () => {
                                                        this.props.api.deleteCustomer(rowData.id)
                                                            .then(r => {
                                                                if (r.status == 200) {
                                                                    this.props.refreshCustomers()
                                                                    this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                } else if (r.status == 420) {
                                                                    this.showError("Du darfst dieses Element nicht löschen.")
                                                                } else {
                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                }
                                                            })
                                                            .catch(r => this.showError("Konnte nicht gelöscht werden."))
                                                    }
                                                })
                                            }} type="button" style={{ marginLeft: 10 }} icon="pi pi-trash" className="p-button-danger"></Button>
                                        </div>
                                    }} />
                                </DataTable>
                                :
                                <p>Keine Elemente gefunden.</p>
                        }

                    </div>
                </div>
            </div>
        );
    }
}
