import React, { Component } from 'react';
import { Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';

export class SettingsPage extends Component {

    constructor() {
        super();
        this.state = {
            secret:undefined,
            currentFields:{
                slack_id:""
            },
            qr_url:undefined
        };
    }

    onFieldChange(e) {
        let currentFields = this.state.currentFields
        currentFields[e.target.name] = e.target.value
        this.setState({ currentFields })
    }

    render() {
        return (
            <div className="p-grid">
            <div className="p-col-12">
            <div className="card docs">
            <h1>Einstellungen</h1>
            <h2>Slack ID verknüpfen</h2>
            {
                (this.props.api.getUserfield("slack_id") !== "0" && this.props.api.getUserfield("slack_id") !== "") ? 
                <>
                <div style={{padding:0,textAlign:"center",fontWeight:"bold",color:"#A6BF49"}}><i className="pi pi-check"/> Dein Konto ist mit Slack verknüpft.</div>
                <Button icon="pi pi-times" style={{margin:"10px auto",display:"block"}} className="p-button-secondary opacity" onClick={() => { 
                    let user = this.props.api.user
                    this.props.api.updateUser({...user,slack_id:"0"})
                        .then(d=>{
                            this.props.api.user.slack_id=""
                            this.setState({...d.data})
                        }).catch(e=>console.error(e))
                }} label="Verknüpfung aufheben" />

                </>
                :
                <div style={{textAlign:"center",margin:"auto"}}>
                <InputText style={{width:140,display:"inline-block"}} name="slack_id" value={this.state.slack_id} onChange={this.onFieldChange.bind(this)} placeholder="Slack ID" />
                &emsp;
                <Button icon="pi pi-check" style={{margin:"10px auto",display:"inline-block"}} onClick={() => { 
                    let user = this.props.api.user
                    this.props.api.updateUser({...user,slack_id:this.state.currentFields.slack_id})
                        .then(d=>{
                            this.props.api.user.slack_id="*"
                            this.setState({...d.data})
                        }).catch(e=>console.error(e))
                }} label="Jetzt verknüpfen" />
                </div>
            } 
            <br/>
            <h2>2-Faktor Authentifizierung</h2>
            {
                this.props.api.getUserfield("secret") === "*" ? 
                <>
                <div style={{padding:0,textAlign:"center",fontWeight:"bold",color:"#A6BF49"}}><i className="pi pi-check"/> Für Dein Konto ist 2-Faktor Authentifizierung aktiviert.</div>
                </>
                :
                <>
                {
                    this.state.secret !== undefined ?
                    <>
                    <div style={{padding:0,textAlign:"center",color:"#375679"}}>Bitte scanne diesen Code in der Google Authenticator App:</div>
                    <br/>
                    <img style={{margin:"auto",display:"block",height:200,width:200}} src={this.state.qr_url}/>
                    </>
                    :
                    <>
                    <div style={{padding:0,textAlign:"center",color:"#375679"}}>Du hast noch keine 2-Faktor Authentifizierung eingerichtet.</div>
                    <Button icon="pi pi-check" style={{margin:"10px auto",display:"block"}} onClick={() => { 
                        this.props.api.get2FA().then(d=>d.json())
                            .then(d=>{
                                this.setState({...d.data})
                            }).catch(e=>console.error(e))
                    }} label="Jetzt aktivieren" />
                    </>
                }
                </>
            } 
            <br/>
            </div>
            </div>
            </div>
        )
    }
}
