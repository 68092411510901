import React, {Component} from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Fieldset} from 'primereact/fieldset';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Toolbar} from 'primereact/toolbar';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import config from "../config";

export class CarePage extends Component {

    constructor(props) {
        super(props)
        this.state = {project: null, plans: null, currentFields: {plan_id: 0, url: ""}}
    }

    componentDidMount() {
        fetch(config.api_url+"/wp_care_plans.json")
            .then(d => d.json())
            .then(plans => {
                this.setState({plans})
            })
    }

    getProject() {
        let project = this.props.projects.filter(project => project.id === this.props.pid)[0]
        return project
    }

    getCareData() {
        try {
            let data = this.getProject()
            let care_data
            if (data !== undefined)
                care_data = data.care_data
            if (care_data !== undefined)
                return JSON.parse(care_data)
            else return null
        } catch (e) {
            return null
        }
    }

    onFieldChange(e) {
        let currentFields = this.state.currentFields
        currentFields[e.target.name] = e.target.value
        this.setState({currentFields})
    }

    render() {

        console.log("plans", this.state.subscription_plans)
        let care = this.getCareData()

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Subscriptions</h1>

                        <div style={{display: "flex", padding: 10, alignItems: "center", justifyContent: "center"}}>
                            <div>Subscription hinzufügen:</div>
                            <Dropdown name="plan_id"
                                      options={this.props.subscription_plans.map(u => {
                                          return {label: u.title, value: u.id}
                                      })}
                                      value={this.state.currentFields.plan_id}
                                      style={{margin: "0 10px", flex: 1}}
                                      onChange={this.onFieldChange.bind(this)} placeholder="Subscription plan"/>
                            <Button onClick={() => {
                                this.props.api.insertSubscription({
                                    pid: this.props.pid,
                                    plan_id: this.state.currentFields.plan_id
                                }).then(res => {
                                    this.props.refreshProject()
                                    this.props.refreshProjects()
                                })
                            }} label="Buchen"></Button>
                        </div>
                        {
                            this.props.subscriptions !== null &&
                            <table className="table-border table-striped table" style={{borderTop: "1px solid #ddd"}}>
                                <tbody>
                                <tr>
                                    <td>Endet</td>
                                    <td>Gebucht</td>
                                    <td>Subscription</td>
                                    <td>Automatische Verlängerung</td>
                                </tr>
                                {this.props.subscriptions.map(sub => {
                                    console.log("sub", sub)
                                    return <tr key={sub.sid}>
                                        <td>{sub.end}</td>
                                        <td>{sub.start}</td>
                                        <td>{sub.title}</td>
                                        <td>
                                            {sub.auto_renewal == "1" ? <span style={{color: "green"}}>Ja</span> :
                                                <span style={{opacity: .4}}>Nein</span>}
                                            {
                                                sub.active == "1" &&
                                                <Button style={{marginLeft: 10}} onClick={() => {
                                                    this.props.api.updateSubscription({
                                                        pid: this.props.pid,
                                                        id: sub.sid,
                                                        auto_renewal: (sub.auto_renewal == "1" ? "0" : "1")
                                                    }).then(res => {
                                                        this.props.refreshProject()
                                                    })
                                                }} label="Ändern"></Button>
                                            }
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>

                {this.getProject() != undefined && this.getProject().care != "" && care == null &&
                    <div className="p-offset-3 p-col-6">
                        <div className="card card-w-title">
                            <h1>Plugin einrichten</h1>
                            <div className="card-body" style={{padding: "15px"}}>
                                <h2>1. Plugin herunterladen</h2>
                                <a style={{textAlign: "center", margin: "20px auto", display: "block"}}
                                   href="https://gitlab.com/domezi/wp-ziegenhagel-careplus/-/archive/master/wp-ziegenhagel-careplus-master.zip">
                                    <Button icon="pi pi-download" label="wp-ziegenhagel-careplus-master.zip"></Button>
                                </a>
                                <h2>2. URL übertragen</h2>
                                {this.state.currentFields.url == "__success" ? <div style={{
                                        margin: "20px auto",
                                        textAlign: "center",
                                        fontSize: "1.2em",
                                        color: "#0a0"
                                    }}>Wurde gespeichert.</div> :
                                    <div style={{display: "block", margin: "15px 0"}}>
                                        <InputText minLength="26" name="url" style={{
                                            marginRight: "10px",
                                            textAlign: "center",
                                            margin: "auto",
                                            display: "block"
                                        }}
                                                   value={this.state.currentFields.url}
                                                   onChange={this.onFieldChange.bind(this)}
                                                   placeholder="Url (www.bla.com)"/>
                                        <Button icon="pi pi-check" onClick={() => {
                                            this.props.api.updateProjectUrl({
                                                id: this.props.pid,
                                                url: this.state.currentFields.url
                                            }).then(res => {
                                                this.setState({
                                                    currentFields: {
                                                        ...this.state.currentFields,
                                                        url: "__success"
                                                    }
                                                })
                                            }).catch(e => {
                                                alert("Fehler")
                                            })
                                        }} style={{margin: "auto", display: "block", marginTop: 5}}
                                                label="Speichern"></Button>
                                    </div>}
                                <h2>3. Uptime Robot</h2>
                                <div style={{display: "flex", margin: "15px auto", textAlign: "center"}}><p
                                    style={{textAlign: "center", margin: "auto"}}>Gehe jetzt ins WordPress Backend und
                                    klicke "Einrichtung abschliessen".</p></div>
                                <h2>4. Projekt neuladen</h2>
                                <div style={{display: "flex", margin: "15px 0"}}>
                                    <Button onClick={() => {
                                        this.props.refreshProject()
                                        window.location.reload()
                                    }} icon="pi pi-refresh" style={{margin: "auto"}} label="Projekt neuladen"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {care !== null && <>

                    <div className="p-col-6" style={{paddingRight: 14}}>
                        <div className="card card-w-title">
                            <h1 style={{width: "100%"}}
                                className={"careicon careicon-" + this.getProject().care}>{this.getProject().care} Paket</h1>
                            <table className="table-border table-striped table">
                                <tbody>
                                {this.state.plans !== null && this.state.plans.map(plan => {
                                    return <tr key={plan.id}>
                                        <td>{plan.Titel}</td>
                                        <td>{plan[this.getProject().care] === "" ? <span
                                            style={{color: "#aaa"}}>Nein</span> : plan[this.getProject().care]}</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="card card-w-title">
                            <h1>Remote System</h1>
                            <table className="table-border table-striped table">
                                <tbody>
                                <tr>
                                    <td>WordPress Version</td>
                                    <td>{care.wordpress_version} (PHP {care.php_version})</td>
                                </tr>
                                <tr>
                                    <td>Secret</td>
                                    <td>{care.secret.substr(0, 8)}...</td>
                                </tr>
                                <tr>
                                    <td>URL</td>
                                    <td>
                                        <a href={"http://" + care.url} target="_blank"><Button
                                            label="Webseite"></Button></a> &nbsp;
                                        <a href={"http://" + care.url + "/wp-admin"} target="_blank"><Button
                                            label="WP Admin"></Button></a> &nbsp;
                                        <a onClick={() => {
                                            fetch(config.api_url+"/wp_care.php", {
                                                method: "POST",
                                                headers: {
                                                    'Content-Type': 'application/x-www-form-urlencoded'
                                                },
                                                body: "secret=" + care.secret + "&action=uninstall"
                                            }).then(res => res.json()).then(res => {
                                                if (res.success) {
                                                    this.props.refreshProject()
                                                    window.location.reload()
                                                } else {
                                                    alert("Fehler")
                                                }
                                            })
                                        }}><Button label="Purge"></Button></a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card card-w-title">
                            <h1>Remote Users</h1>
                            <table className="table-border table-striped table">
                                <tbody>
                                {
                                    care.users != null && care.users.reverse().map((user, index) => {
                                        return <tr key={user.ID + index}>
                                            <td>{user.ID}</td>
                                            <td>{user.user_login}</td>
                                            <td>{user.user_email}</td>
                                            <td>{user.roles?.join(", ")}</td>

                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="card card-w-title">
                            <h1>Remote Plugins</h1>
                            <table className="table-border table-striped table">
                                <tbody>
                                {
                                    care.plugins != null && care.plugins.map(plugin => {
                                        return <tr key={plugin.title}>
                                            <td>{plugin.title}</td>
                                            <td>{plugin.version}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </>}

            </div>
        );
    }
}
