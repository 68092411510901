import React, {Component} from 'react';
import {DataTable, Column} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Growl} from 'primereact/growl';
import {projectStates, projectTypes, projectCarePlans} from '../Api'
import {Dropdown} from 'primereact/dropdown';

function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '') // collapse whitespace and replace by -
        .replace(/[li]/g, '') // collapse whitespace and replace by -
        .replace(/-+/g, ''); // collapse dashes

    return str.substr(0, 6);
}


export class ProjectsPage extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.location)

        let createProjectWithCustomer_cid = undefined

        if (this.props.location.state !== undefined)
            createProjectWithCustomer_cid = this.props.location.state.createProjectWithCustomer_cid

        //console.log("createProjectWithCustomer_cid:",createProjectWithCustomer_cid)

        this.state = {
            dialogVisible: (createProjectWithCustomer_cid !== undefined),
            confirmVisible: false,
            confirmMessage: "Möchten Sie fortfahren?",
            confirmFun: () => {
                console.log("not implemented")
            },
            searchPattern: "",
            isSearch: true,
            currentFields: {
                title: "", slug: "",
                care: "", url: "", budget: "",
                cid: "" + createProjectWithCustomer_cid,
                uid: 0, iss: "", status: "", type: "website"
            },
            currentWage: {pid: 0, uid: 0, wage: 47},
            wages: [],
            showArchive: false,
            currentAction: "insert",
        };

    }

    refreshWages() {
        this.props.api.getWages(this.state.currentWage.pid)
            .then(r => r.json())
            .then(r => this.setState({wages: r.data}))
            .catch(e => this.showError("Konnte Stundenlohn-Tabelle nicht laden."))
    }

    onWageChange(e) {
        let currentWage = this.state.currentWage
        currentWage[e.target.name] = e.target.value
        this.setState({currentWage})
    }

    onFieldChange(e) {
        if (e.target.name === "searchPattern") {
            this.setState({searchPattern: e.target.value})
        } else {
            let currentFields = this.state.currentFields
            currentFields[e.target.name] = e.target.value

            if (e.target.name === "title")
                currentFields["slug"] = string_to_slug(e.target.value);

            this.setState({currentFields})
        }
    }

    showSuccess(detail) {
        this.growl.show({closable: true, severity: 'success', summary: 'Erfolgreich druchgeführt!', detail});
    }

    showError(detail) {
        this.growl.show({closable: true, severity: 'error', summary: 'Fehler!', detail});
    }


    rowClassName(rowData) {
        return {'opacity': true};
    }


    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                        <h1>Projekte</h1>
                        <div className="header-actions">
                            <Button icon="pi pi-briefcase" className={this.state.showArchive ? "" : "notactive"}
                                    onClick={() => {
                                        this.setState({showArchive: !this.state.showArchive})
                                    }} label=""/>
                            <Button icon="pi pi-search" className={this.state.isSearch ? "" : "notactive"}
                                    onClick={() => {
                                        this.setState({searchPattern: "", isSearch: !this.state.isSearch})
                                    }} label=""/>
                            <Button disabled={!this.props.api.hasPolicy("projects_create")} icon="pi pi-plus"
                                    onClick={() => {
                                        this.setState({
                                            currentAction: "insert",
                                            currentFields: {
                                                title: "",
                                                slug: "",
                                                cid: 0,
                                                uid: 0,
                                                iss: "",
                                                status: "draft",
                                                type: "website"
                                            },
                                            dialogVisible: true
                                        })
                                    }} label="Hinzufügen"/>
                        </div>

                        <div className="subtitle">
                            Hier siehst Du die Projekte, an denen Du beteiligt bist. Mit dem grünen Pfeil kannst Du ein
                            Projekt aktivieren(fett gedruckt) oder archivieren (ausgegraut). <b>Du kannst nur an aktiven
                            Projekten arbeiten.</b><br/>
                            Wenn Du ein neues Projekt anlegst, oder aus dem Archiv holst, hat es den Status
                            Entwurf(normal gedruckt). Das ist Vorteilhaft, wenn gerade ein neues Projekt reinkommt, aber
                            noch nicht sicher ist, ob es zu einem Auftrag wird.<br/>
                            Mit dem Auge kannst Du ein Projekt auswählen, unabhängig davon, welchen Status es gerade
                            hat.
                            <br/>
                            <br/><b>Besondere Benennungen:</b><br/>
                            "ø Projektname" - Wenn noch keine Erstmail versendet wurde.<br/>
                            "† Projektname" - Kunde nicht mehr kontaktieren, bis zu einer Antwort.
                        </div>

                        <Dialog style={{textAlign: "center"}} header="Fortfahren?" visible={this.state.confirmVisible}
                                modal={true} onHide={() => this.setState({confirmVisible: false})}
                                footer={<div>
                                    <Button onClick={() => {
                                        this.setState({confirmVisible: false})
                                    }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                    <Button onClick={() => {
                                        this.state.confirmFun();
                                        this.setState({confirmVisible: false})
                                    }} label="Fortfahren" className="p-button-primary" icon="pi pi-check"/>
                                </div>}>{this.state.confirmMessage}</Dialog>

                        <Dialog header={
                            this.state.currentAction === "insert" ?
                                "Hinzufügen" : this.state.currentAction === "edit" ? "Bearbeiten" : "An diesem Projekt beteiligte"
                        } visible={this.state.dialogVisible} modal={true}
                                onHide={() => this.setState({dialogVisible: false})}
                                footer={<div>
                                    {
                                        this.state.currentAction === "wages" ?
                                            <Button onClick={() => {
                                                this.setState({dialogVisible: false})
                                            }} label="Schliessen" className="p-button-secondary" icon="pi pi-times"/>
                                            :
                                            <div>
                                                <Button onClick={() => {
                                                    this.setState({dialogVisible: false})
                                                }} label="Abbrechen" className="p-button-secondary" icon="pi pi-times"/>
                                                <Button onClick={() => {
                                                    if (this.state.currentAction === "insert") {
                                                        // add the project
                                                        this.props.api.insertProject(this.state.currentFields)
                                                            .then((r) => {
                                                                return r.json()
                                                            })
                                                            .then((r) => {
                                                                this.setState({dialogVisible: false})
                                                                this.showSuccess("Gespeichert.")
                                                                console.log(r)
                                                                setTimeout(() => {
                                                                    this.props.onProjectChoose(r.insert_id)
                                                                }, 500);
                                                                setTimeout(() => {
                                                                    this.props.onProjectChoose(r.insert_id)
                                                                }, 900);
                                                                this.props.refreshProjects()
                                                            })
                                                            .catch((e) => {
                                                                this.showError("Konnte nicht erstellt werden.")
                                                            })
                                                    } else {
                                                        // update the project
                                                        this.props.api.updateProject(this.state.currentFields)
                                                            .then((r) => {
                                                                this.setState({dialogVisible: false})
                                                                this.showSuccess("Änderungen gespeichert.")
                                                            })
                                                            .catch((e) => {
                                                                console.error("updateProject error", e)
                                                                this.showError("Speichern der Änderungen fehlgeschlagen.")
                                                            })
                                                    }
                                                }}
                                                        label={this.state.currentAction === "insert" ? "Hinzufügen" : "Speichern"}
                                                        icon="pi pi-check"/>
                                            </div>
                                    }
                                </div>}
                                className="form"
                        >
                            <div>
                                {
                                    // stundenlohn tabelle
                                    // wage create
                                    this.state.currentAction === "wages" ?
                                        <div>
                                            {
                                                this.props.api.hasPolicy("wages_create")
                                                &&
                                                <div>
                                                    <Dropdown name="uid" value={this.state.currentWage.uid} options={
                                                        this.props.users.map(users => {
                                                            return {
                                                                label: users.fname + " " + users.lname,
                                                                value: users.id
                                                            }
                                                        })
                                                    } onChange={this.onWageChange.bind(this)}
                                                              placeholder="Mitarbeiter wählen"/>
                                                    <InputText type="number" name="wage"
                                                               value={this.state.currentWage.wage <= 0 ? "" : this.state.currentWage.wage}
                                                               onChange={this.onWageChange.bind(this)}
                                                               placeholder="Stundenlohn (in €)"/>

                                                    <Button onClick={() => {
                                                        this.props.api.insertWage(this.state.currentWage)
                                                            .then(d => this.refreshWages())
                                                            .catch(e => this.refreshWages())
                                                    }} label="Hinzufügen" className="p-button-primary"
                                                            icon="pi pi-plus"/>
                                                    <hr/>
                                                </div>
                                            }

                                            {/* END wages create */}
                                            {/* START wages list */}

                                            {
                                                this.state.wages.length === 0 ?
                                                    <i className="pi pi-spin pi-refresh" style={{
                                                        margin: "10px auto",
                                                        display: "block",
                                                        textAlign: "center",
                                                        fontSize: "3em"
                                                    }}></i>
                                                    :
                                                    <DataTable value={this.state.wages}>
                                                        <Column field="uid" header="Mitarbeiter" body={(row) => {
                                                            for (let i = 0; i < this.props.users.length; i++) {
                                                                let user = this.props.users[i]
                                                                if (user.id === row.uid)
                                                                    return user.fname + " " + user.lname
                                                            }
                                                        }}></Column>
                                                        <Column field="wage" header="Stundenlohn" body={(row) => {
                                                            return row.wage + "€/h"
                                                        }}></Column>
                                                        <Column style={{width: "6em"}} body={(row) => <Button
                                                            disabled={!this.props.api.hasPolicy("wages_delete")}
                                                            onClick={() => {
                                                                this.props.api.deleteWage(row)
                                                                    .then(r => {
                                                                        this.refreshWages()
                                                                    })
                                                            }} type="button" icon="pi pi-trash"
                                                            className="p-button-danger"></Button>
                                                        }/>
                                                    </DataTable>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <InputText name="title" value={this.state.currentFields.title}
                                                       onChange={this.onFieldChange.bind(this)}
                                                       placeholder="Projektname"/>
                                            <Dropdown name="cid" value={this.state.currentFields.cid} options={
                                                this.props.customers.map(customer => {
                                                    return {
                                                        label: customer.title + customer.fname + " " + customer.lname,
                                                        value: customer.id
                                                    }
                                                })
                                            } onChange={this.onFieldChange.bind(this)} placeholder="Kunde"/>
                                            <Dropdown name="type" value={this.state.currentFields.type}
                                                      options={projectTypes} onChange={this.onFieldChange.bind(this)}
                                                      placeholder="Typ"/>
                                            <Dropdown name="care" value={this.state.currentFields.care}
                                                      options={projectCarePlans}
                                                      onChange={this.onFieldChange.bind(this)} placeholder="Care plan"/>
                                            <Dropdown name="status" style={{display: "none"}}
                                                      value={this.state.currentFields.status} options={projectStates}
                                                      onChange={this.onFieldChange.bind(this)} placeholder="Status"/>
                                            <InputText name="budget" value={this.state.currentFields.budget}
                                                       onChange={this.onFieldChange.bind(this)}
                                                       placeholder="Budget in €"/>
                                            <InputText minLength="26" name="url" value={this.state.currentFields.url}
                                                       onChange={this.onFieldChange.bind(this)}
                                                       placeholder="Url (www.bla.com)"/>
                                            <InputText minLength="6" style={{textTransform: "uppercase"}} maxLength="6"
                                                       name="slug" value={this.state.currentFields.slug}
                                                       onChange={this.onFieldChange.bind(this)}
                                                       placeholder="Slug (z.B: DOMZIE, PAUWEI, JFCANM, DOMZI2)"/>
                                        </div>
                                }
                            </div>
                        </Dialog>

                        {this.props.projects.length > 0 && this.state.isSearch &&
                        <InputText name="searchPattern" onKeyUp={(e) => {
                            if (e.keyCode === 27) this.setState({searchPattern: ""});
                            if (e.keyCode === 13 || e.keyCode === 27) this.setState({isSearch: false})
                        }} value={this.state.searchPattern} onChange={this.onFieldChange.bind(this)}
                                   placeholder="Suchen nach ..." autoFocus={true}/>}

                        {
                            this.props.projects.length > 0 ?
                                <DataTable rowClassName={(r) => {
                                    return {
                                        colorProjectArchive: r.status === "archive",
                                        colorProjectActive: r.status === "active"
                                    }
                                }}
                                           sortField={"id"}
                                           responsiveLayout={"scroll"}
                                           value={this.props.projects.filter(project => project.status !== "archive" || this.state.showArchive).filter((o) => {
                                               return (this.state.searchPattern === "" || o.slug.toLowerCase().includes(this.state.searchPattern.toLowerCase()) || o.title.toLowerCase().includes(this.state.searchPattern.toLowerCase()))
                                           })}>
                                    <Column sortable field={"id"} header={"ID"} style={{width: 70}}/>
                                    <Column field={"slug"} header={"Slug"} body={(r, c) => {
                                        return <div>
                                            <i className={"pi pi-" + (r.type === "website" ? "globe" : r.type === "imagefilm" ? "video" : "file")}></i>&nbsp;
                                            {r.slug}
                                        </div>
                                    }}/>
                                    <Column sortable field={"title"} header={"Projektname"} body={(r, c) => {
                                        return <span style={{
                                            color: r.title.substr(0,1) == 'ø' ? 'coral' : r.title.substr(0,1) == '†' ? 'gray' : 'inherit'
                                        }}>{r.title}</span>
                                    }}/>
                                    <Column sortable field={"care"} header={"Care"}/>
                                    <Column sortable field={"cid"} header={"Kunde*in"} body={(row, col) => {
                                        return this.props.customers.map(customer => {
                                            if (customer.id === row.cid)
                                                return customer.fname + " " + customer.lname
                                        })
                                    }}/>
                                    <Column sortable field={"uid"} header={"Ansprechpartner*in"} body={(row, col) => {
                                        return this.props.users.map(user => {
                                            if (user.id === row.uid)
                                                return user.fname[0] + ". " + user.lname
                                        })
                                    }}/>
                                    <Column className="nobold" style={{width: "17em", textAlign: "center"}}
                                            body={(rowData, column) => {
                                                return <div>
                                                    <Button onClick={() => {
                                                        this.props.onProjectChoose(rowData.id)
                                                    }} type="button" icon="pi pi-eye"
                                                            className={"p-button-" + (this.props.pid === rowData.id ? "primary" : "secondary")}></Button>
                                                    <Button disabled={!this.props.api.hasPolicy("projects_edit")}
                                                            onClick={() => {
                                                                this.setState({
                                                                    dialogVisible: true,
                                                                    currentAction: "edit",
                                                                    currentFields: rowData
                                                                }, () => {
                                                                    console.log(this.state)
                                                                })
                                                            }} type="button" style={{marginLeft: 10}}
                                                            icon="pi pi-pencil" className="p-button-secondary"></Button>
                                                    <Button
                                                        disabled={!this.props.api.hasPolicy("wages_edit") && !this.props.api.hasPolicy("wages_list")}
                                                        onClick={() => {
                                                            this.setState({
                                                                wages: [],
                                                                dialogVisible: true,
                                                                currentAction: "wages",
                                                                currentWage: {
                                                                    pid: rowData.id,
                                                                    uid: this.state.currentWage.uid,
                                                                    wage: 47
                                                                }
                                                            }, () => {
                                                                this.refreshWages()
                                                            })
                                                        }} type="button" style={{marginLeft:10}} icon="pi pi-user-plus"
                                                        className="p-button-secondary"></Button>
                                                    <Button disabled={!this.props.api.hasPolicy("projects_delete")}
                                                            onClick={() => {
                                                                this.setState({
                                                                    confirmVisible: true,
                                                                    confirmMessage: "Das Projekt " + rowData.title + " " + (rowData.status === "archive" ? "wieder aktivieren" : "archivieren") + "?",
                                                                    confirmFun: () => {
                                                                        this.props.api.updateProject({
                                                                            id: rowData.id,
                                                                            status: (rowData.status === "archive" ? "active" : "archive")
                                                                        })
                                                                            .then(r => {
                                                                                if (r.status == 200) {
                                                                                    this.props.refreshProjects()
                                                                                    this.showSuccess("Wurde erfolgreich " + (rowData.status === "archive" ? "wieder aktiviert" : "archiviert") + ".")
                                                                                } else if (r.status == 420) {
                                                                                    this.showError("Du darfst dieses Element nicht" + (rowData.status === "archive" ? "wieder aktiviert" : "archiviert") + "!");
                                                                                } else {
                                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                                }
                                                                            })
                                                                            .catch(r => this.showError("Konnte nicht aktualisiert werden."))
                                                                    }
                                                                })
                                                            }} type="button" style={{marginLeft: 10}}
                                                            icon={"pi pi-" + (rowData.status === "archive" ? "replay" : "ban")}
                                                            className="p-button-warning"></Button>
                                                    {rowData.status === "active" &&
                                                    <Button disabled={!this.props.api.hasPolicy("projects_edit")}
                                                            onClick={() => {
                                                                this.setState({
                                                                    confirmVisible: true,
                                                                    confirmMessage: "Das Projekt " + rowData.title + " deaktivieren?",
                                                                    confirmFun: () => {
                                                                        this.props.api.updateProject({
                                                                            id: rowData.id,
                                                                            status: "draft"
                                                                        })
                                                                            .then(r => {
                                                                                if (r.status == 200) {
                                                                                    this.props.refreshProjects()
                                                                                    this.showSuccess("Wurde erfolgreich deaktiviert.")
                                                                                } else if (r.status == 420) {
                                                                                    this.showError("Du darfst dieses Element nicht deaktiviert.")
                                                                                } else {
                                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                                }
                                                                            })
                                                                            .catch(r => this.showError("Konnte nicht deaktiviert werden."))
                                                                    }
                                                                })
                                                            }} type="button" style={{marginLeft: 10}}
                                                            icon={"pi pi-angle-down"}
                                                            className="p-button-success"></Button>
                                                    }
                                                    {rowData.status === "draft" &&
                                                    <Button disabled={!this.props.api.hasPolicy("projects_edit")}
                                                            onClick={() => {
                                                                this.setState({
                                                                    confirmVisible: true,
                                                                    confirmMessage: "Das Projekt " + rowData.title + " aktivieren?",
                                                                    confirmFun: () => {
                                                                        this.props.api.updateProject({
                                                                            id: rowData.id,
                                                                            status: "active"
                                                                        })
                                                                            .then(r => {
                                                                                if (r.status == 200) {
                                                                                    this.props.refreshProjects()
                                                                                    this.showSuccess("Wurde erfolgreich aktiviert.")
                                                                                } else if (r.status == 420) {
                                                                                    this.showError("Du darfst dieses Element nicht aktiviert.")
                                                                                } else {
                                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                                }
                                                                            })
                                                                            .catch(r => this.showError("Konnte nicht aktiviert werden."))
                                                                    }
                                                                })
                                                            }} type="button" style={{marginLeft: 10}}
                                                            icon={"pi pi-angle-up"}
                                                            className="p-button-success"></Button>
                                                    }
                                                    {rowData.status === "archive" &&
                                                    <Button disabled={!this.props.api.hasPolicy("projects_delete")}
                                                            onClick={() => {
                                                                this.setState({
                                                                    confirmVisible: true,
                                                                    confirmMessage: "Das Projekt " + rowData.title + " löschen?",
                                                                    confirmFun: () => {
                                                                        this.props.api.deleteProject(rowData.id)
                                                                            .then(r => {
                                                                                if (r.status == 200) {
                                                                                    this.props.refreshProjects()
                                                                                    this.showSuccess("Wurde erfolgreich gelöscht.")
                                                                                } else if (r.status == 420) {
                                                                                    this.showError("Du darfst dieses Element nicht löschen.")
                                                                                } else {
                                                                                    this.showError("Es ist ein Fehler aufgetreten.")
                                                                                }
                                                                            })
                                                                            .catch(r => this.showError("Konnte nicht gelöscht werden."))
                                                                    }
                                                                })
                                                            }} type="button" style={{marginLeft: 10}} icon="pi pi-trash"
                                                            className="p-button-danger"></Button>
                                                    }
                                                </div>
                                            }}/>
                                </DataTable>
                                :
                                <p>Keine Elemente gefunden.</p>
                        }

                    </div>
                </div>
            </div>
        );
    }
}
