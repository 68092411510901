import React, { Component } from 'react';
import { CountryService } from '../service/CountryService';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Spinner } from 'primereact/spinner';
import { Slider } from 'primereact/components/slider/Slider';
import { ListBox } from 'primereact/listbox';
import { Rating } from 'primereact/rating';
import { ColorPicker } from 'primereact/colorpicker';
import { Editor } from 'primereact/editor';
import { ToggleButton } from 'primereact/togglebutton';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
//import "../layout/login.css"

export class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = { auth: "jwt", email: "", pass: "", error: false, code: "", codeRequired: false }

        const ENDPOINT = "https://overtime-socket.onrender.com/";
        fetch(ENDPOINT).then(d=>d.json()).catch(e=>console.error())
    }
    onChange(e) {
        if (e.target.name === "email")
            this.setState({ email: e.target.value })
        else if(e.target.name === "pass")
            this.setState({ pass: e.target.value })
        else if(e.target.name === "code" ) {
            let code = e.target.value
            this.setState({ code },()=>{
                if(code.length === 6)
                    this.login(null)
            })
        }
    }
    login(e) {
        let stateCopy = this.state
        this.props.api.auth(stateCopy)
            .then(d => d.json())
            .then(d => {
                if(d.data === undefined) {
                    if(d.codeRequired)
                        this.setState({codeRequired:d.codeRequired})
                } else 
                    this.props.api.setAccessToken(d.data.hash)
            })
            .catch(ef => {if(e!==null)this.setState({ error: true })})
        if(e!== null)
            e.preventDefault()
        return false
    }
    render() {
        return (
            <div className="login">
                <div className="p-col-12 p-lg-6" style={{ margin: "auto", marginTop: 50 }}>
                    <div className="card card-w-title" style={{ boxShadow: "5px 5px 20px #0004" }}>
                        <h1>Overtime</h1>
                        {
                            this.state.error &&
                            <div style={{ color: "white", fontSize: "1.3em", textAlign: "center", background: "#a00", padding: "10px 20px", border: "1px solid #a00", marginBottom: 15, marginTop: -18 }}> Bitte überprüfen Sie die Zugangsdaten. </div>
                        }
                        <form onSubmit={(e) => { this.login(e); return false }}  method="post">
                            <div className="form">
                                <InputText name="email" autoFocus={true} value={this.state.email} onChange={this.onChange.bind(this)} placeholder="Email" />
                                <InputText name="pass" value={this.state.pass} onChange={this.onChange.bind(this)} type="password" placeholder="Password" />
                                { this.state.codeRequired && <InputText name="code" autoFocus={true} value={this.state.code} onChange={this.onChange.bind(this)} placeholder="2FA Code" /> }
                                <Button onClick={this.login.bind(this)} label="Login" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
